import React, { useState, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { Table, Space, Select, Typography } from "antd";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  getIsAdminLogin,
  getEmployeeId,
  minsToHours,
} from "../../utils/Common";

const { Title } = Typography;
export default function PerformanceEvalutaion() {
  const componentRef = useRef();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const dateFormat = "YYYY-MM-DD";
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [searchTerm, setSearchTerm] = useState(null);

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin ? "" : employeeId,
  });

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Absent (days)",
      dataIndex: "absent_count",
      key: "absent_count",
    },
    {
      title: "Present (days)",
      dataIndex: "present_count",
      key: "present_count",
    },
    {
      title: "Regular OT (hrs)",
      dataIndex: "regular_ot_hrs",
      key: "regular_ot_hrs",
      // render: (data, record) => (
      //   <div className="pointer-cursor" onClick={() => handleRowClick(record)}>
      //     {parseFloat(Number(data)*60).toFixed(2)}
      //   </div>
      // ),
    },
    {
      title: "Restday OT (hrs)",
      dataIndex: "restday_ot_hrs",
      key: "restday_ot_hrs",
      // render: (data, record) => (
      //   <div className="pointer-cursor" onClick={() => handleRowClick(record)}>
      //     {parseFloat(Number(data)*60).toFixed(2)}
      //   </div>
      // ),
    },
    {
      title: "Late (hrs)",
      dataIndex: "late",
      key: "late",
      // render: (data, record) => (
      //   <div className="pointer-cursor" onClick={() => handleRowClick(record)}>
      //     {parseFloat(Number(data)*60).toFixed(2)}
      //   </div>
      // ),
    },
    {
      title: "Undertime (hrs)",
      dataIndex: "undertime_hrs",
      key: "undertime_hrs",
      // render: (data, record) => (
      //   <div className="pointer-cursor" onClick={() => handleRowClick(record)}>
      //     {parseFloat(Number(data)*60).toFixed(2)}
      //   </div>
      // ),
    },
  ];

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  const handleDateFilter = (e) => {
    if (e) {
      setFilterConfig((prev) => ({
        ...prev,
        date_from: e[0].format(dateFormat),
        date_to: e[1].format(dateFormat),
      }));
    }
  };

  const handleRowClick = (rowData) => {
    const passValues = {
      id: rowData.employee_id,
      fullname: rowData.employee_name,
      date_from: filterConfig.date_from,
      date_to: filterConfig.date_to,
    };
    navigate("/employees/history-report", { state: passValues });
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest(
        "timesheets/performance_evaluation",
        filterConfig
      );
      if (response.data) {
        setTableData(response.data);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Performance Evaluation Report"
      noButton
      withPrintBtn
      printContent={componentRef}
      withDateRangePicker
      onDateChange={handleDateFilter}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      isManager
      searchable={isAdminLogin}
    >
      <Table
        className="mt-4"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
      />

      <div className="printOnly">
        <div className="print-report-page" ref={componentRef}>
          <Title level={5} className="px-4">
            PERFORMANCE EVALUATION
          </Title>
          <Table
            className="px-2 mt-3"
            rowKey="id"
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </div>
      </div>
    </Navbar>
  );
}
