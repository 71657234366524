import React from "react";
import { Modal, Row, Col, Card } from "antd";
import moment from "moment";


export default function EmployeeListModal(props) {
    const {show, onCancel, data, focusDate, header, countColumn} = props;
    function calculateYears (date) {
        const currentDate = new Date();
        const passedDate = new Date(date);
        const yearsDiff = currentDate.getFullYear() - passedDate.getFullYear();
        const monthsDiff = currentDate.getMonth() - passedDate.getMonth();
        const daysDiff = currentDate.getDate() - passedDate.getDate();
    
        let differenceInYears = yearsDiff + monthsDiff / 12;
    
        if (daysDiff > 0) {
          const currentMonthDays = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
          const fractionOfMonth = daysDiff / currentMonthDays;
          differenceInYears += fractionOfMonth / 12;
        } else if (daysDiff < 0) {
          const prevMonthDays = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
          const fractionOfMonth = daysDiff / prevMonthDays;
          differenceInYears += fractionOfMonth / 12;
        }
        // return parseFloat(differenceInYears).toFixed(3)
        return parseInt(differenceInYears)
      }

    return (
        <Modal open={show} onCancel={onCancel} footer={null} title={data.title?.toUpperCase()} width={800}>
            <Row className="d-flex justify-content-between mt-3 m-1">
                <Col xs={13} className="bold">EMPLOYEE</Col>
                <Col xs={7} className="bold">{header}</Col>
                {
                    countColumn && <Col xs={4} className="text-center bold">{countColumn}</Col>
                }
            </Row>
            {
            data.list.map((row, index) => (
                <Card key={index}
                    className="m-1 mt-0"
                    bodyStyle={{ padding: 10 }}>
                        <Row className="d-flex justify-content-between">
                            {
                                row.employee_name ? (
                                    <Col xs={13}>{row.employee_name}</Col>
                                ) : (
                                    <Col xs={13}>{`${row.first_name} ${row.middle_name} ${row.last_name}`}</Col>
                                )
                            }
                            
                            <Col xs={7}>{moment(row[focusDate]).format("MMM DD, YYYY")}</Col>
                            {
                                countColumn && <Col xs={4} className="text-center">{calculateYears(row[focusDate])}</Col>
                            }
                        </Row>
                    </Card>
                ))
            }
        </Modal>
    )
}