import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TabProvider } from "./context/TabContext";
import { Toaster } from "react-hot-toast";
import { globalStyles } from "./utils/theme";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/cssFiles/CommonStyles/Common.css';
import '../src/cssFiles/CommonStyles/Responsiveness.css';
import { getToken, getRoleName } from "./utils/Common";

import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import EmployeeDashboard from "./pages/Dashboard/EmployeeDashboard";
import Payroll from "./pages/Payroll/Payroll";
import Payslip from "./pages/Payroll/Payslip";
import GeneratePayroll from "./pages/Payroll/GeneratePayroll";
import ThirteenthMonth from "./pages/Reports/ThirteenthMonth";
import Timesheet from "./pages/Timesheet/Timesheet";
import UploadTimesheet from "./pages/Timesheet/UploadTimesheet";
import Memorandum from "./pages/Memorandum/Memorandum";
import ViewMemorandum from "./pages/Memorandum/ViewMemorandum";
import Announcement from "./pages/Announcement/Announcement";
import ViewAnnouncement from "./pages/Announcement/ViewAnnouncement";
import CommunicationLetter from "./pages/CommunicationLetter/CommunicationLetter";
import ViewCommunicationLetter from "./pages/CommunicationLetter/ViewCommunicationLetter";
import Leave from "./pages/Leave/Leave";
import ViewLeave from "./pages/Leave/ViewLeave";
import Overtime from "./pages/Overtime/Overtime";
import WorkFromAnywhere from "./pages/WorkFromAnywhere/WorkFromAnywhere";
import ViewWorkFromAnywhere from "./pages/WorkFromAnywhere/ViewWorkFromAnywhere";
import PerformanceEvaluation from "./pages/Reports/PerformanceEvaluation";
import Loan from "./pages/Loan/Loan";
import ViewLoan from "./pages/Loan/ViewLoan";

// // BIR Form
// import Form1601C from "./pages/BirForm/Form1601C";
// import Form1604C from "./pages/BirForm/Form1604C";
// import Form2316 from "./pages/BirForm/Form2316";

// // Manual BIR FORM
// import ManualForm1601C from "./pages/BirManualForm/ManualForm1601C";
// import ManualForm1604C from "./pages/BirManualForm/ManualForm1604C";
// import ManualForm2316 from "./pages/BirManualForm/ManualForm2316";
// import GenerateForm1601C from "./pages/BirManualForm/GenerateForm1601C";
// import GenerateForm1604C from "./pages/BirManualForm/GenerateForm1604C";
// import GenerateForm2316 from "./pages/BirManualForm/GenerateForm2316";

// Contribution Report
import PhicContributionReport from "./pages/PhicContributionReport/PhicContributionReport";
import ViewEmployeePhicContribution from "./pages/PhicContributionReport/ViewEmployeePhicContribution";
import PrintPhicContributionReport from "./pages/PhicContributionReport/PrintPhicContributionReport";
import SssContributionReport from "./pages/SssContributionReport/SssContributionReport";
import ViewEmployeeSssContribution from "./pages/SssContributionReport/ViewEmployeeSssContribution";
import PrintSssContributionReport from "./pages/SssContributionReport/PrintSssContributionReport";
import HdmfContributionReport from "./pages/HdmfContributionReport/HdmfContributionReport";
import ViewEmployeeHdmfContribution from "./pages/HdmfContributionReport/ViewEmployeeHdmfContribution";
import PrintHdmfContributionReport from "./pages/HdmfContributionReport/PrintHdmfContributionReport";

// Manage Employee Modules
import Employee from "./pages/Employee/Employee";
import EmployeeProfile from "./pages/Employee/EmployeeProfile";
import EmployeeHistoryReport from "./pages/Employee/EmployeeHistoryReport";
import LeaveCredit from "./pages/Reports/LeaveCredit";
import FaceEnrollment from "./pages/FaceRecognition/FaceEnrollment/FaceEnrollment";
import FaceAttendance from "./pages/FaceRecognition/FaceAttendance/FaceAttendance";
import EventsCalendar from "./pages/EventsCalendar/EventsCalendar";

// Manage Systems
import BusinessUnit from "./pages/BusinessUnit/BusinessUnit";
import Department from "./pages/Department/Department";
import LetterType from "./pages/LetterType/LetterType";
import Position from "./pages/Position/Position";
import Holiday from "./pages/Holiday/Holiday";
import User from "./pages/User/User";

/**
 *  MAIN APP
 */
function App() {
  const userToken = getToken();
  const isAdminLogin = getRoleName()==="admin";
  const isEmployeeLogin = getRoleName()==="employee";
  const isEncoderLogin = getRoleName()==="hr";

  
  return (
    <div>
      <TabProvider>
      <Toaster 
        position="top-right" 
        reverseOrder={false} 
        toastOptions={{
          style:{
            fontFamily: globalStyles.fontRegular,
            textTransform: 'capitalize'
          }
        }}
      />
      <Router>
        <Routes>
            <Route
              path="/"
              element={<Login />}
            />
            <Route
              path="/dashboard"
              element={userToken && !isEmployeeLogin ? <Dashboard/> : <Login/>}
            />
            <Route
              path="/employeedashboard"
              element={userToken && isEmployeeLogin ? <EmployeeDashboard/> : <Login/>}
            />
             <Route
              path="/dashboard/eventscalendar"
              element={userToken && !isEmployeeLogin ? <EventsCalendar/> : <Login/>}
            />
            <Route
              path="/payrolls"
              element={userToken && !isEncoderLogin ? <Payroll/> : <Login/>}
            />
            <Route
              path="/payrolls/generate"
              element={userToken&&isAdminLogin ? <GeneratePayroll/> : <Login/>}
            />
            <Route
              path="/payrolls/payslip/:id"
              element={userToken && !isEncoderLogin? <Payslip/> : <Login/>}
            />
            <Route
              path="/thirteenth-month-report"
              element={userToken && isAdminLogin ? <ThirteenthMonth/> : <Login/>}
            />
            <Route
              path="/timesheet"
              element={userToken && !isEmployeeLogin ? <Timesheet/> : <Login/>}
            />
            <Route
              path="/timesheet/upload"
              element={userToken && !isEmployeeLogin ? <UploadTimesheet/> : <Login/>}
            />
            <Route
              path="/memorandums"
              element={userToken && !isEmployeeLogin ? <Memorandum/> : <Login/>}
            />
            <Route
              path="/memorandums/view"
              element={userToken && !isEmployeeLogin ? <ViewMemorandum/> : <Login/>}
            />
            <Route
              path="/announcements"
              element={userToken && !isEmployeeLogin ? <Announcement/> : <Login/>}
            />
            <Route
              path="/announcements/view"
              element={userToken && !isEmployeeLogin ? <ViewAnnouncement/> : <Login/>}
            />
            <Route
              path="/communication-letters"
              element={userToken && !isEmployeeLogin ? <CommunicationLetter/> : <Login/>}
            />
            <Route
              path="/communication-letters/view"
              element={userToken ? <ViewCommunicationLetter/> : <Login/>}
            />
            <Route
              path="/leaves"
              element={userToken ? <Leave/> : <Login/>}
            />
            <Route
              path="/leaves/view"
              element={userToken ? <ViewLeave/> : <Login/>}
            />
            <Route
              path="/overtimes"
              element={userToken ? <Overtime/> : <Login/>}
            />
            <Route
              path="/wfas"
              element={userToken ? <WorkFromAnywhere/> : <Login/>}
            />
            <Route
              path="/wfas/view"
              element={userToken ? <ViewWorkFromAnywhere/> : <Login/>}
            />
            <Route
              path="/loans"
              element={userToken ? <Loan/> : <Login/>}
            />
            <Route
              path="/loans/view"
              element={userToken ? <ViewLoan/> : <Login/>}
            />
            <Route
              path="/performance-evaluation"
              element={userToken ? <PerformanceEvaluation/> : <Login/>}
            />

            {/* BIR FORMS */}
            {/* <Route
              path="/form1601c"
              element={userToken ? <Form1601C/> : <Login/>}
            />
            <Route
              path="/form1604c"
              element={userToken ? <Form1604C/> : <Login/>}
            />
            <Route
              path="/form2316"
              element={userToken ? <Form2316/> : <Login/>}
            /> */}

            {/* MANUAL BIR FORMS */}
            {/* <Route
              path="/manualform1601c"
              element={userToken ? <ManualForm1601C/> : <Login/>}
            />
            <Route
              path="/manualform1601c/generate"
              element={userToken ? <GenerateForm1601C/> : <Login/>}
            />
            <Route
              path="/manualform1604c"
              element={userToken ? <ManualForm1604C/> : <Login/>}
            />
            <Route
              path="/manualform1604c/generate"
              element={userToken ? <GenerateForm1604C/> : <Login/>}
            />
            <Route
              path="/manualform2316"
              element={userToken ? <ManualForm2316/> : <Login/>}
            />
            <Route
              path="/manualform2316/generate"
              element={userToken ? <GenerateForm2316/> : <Login/>}
            /> */}

            {/* CONTRIBUTION REPORTS */}
            <Route
              path="/phic-contribution-report"
              element={userToken && !isEmployeeLogin ? <PhicContributionReport/> : <Login/>}
            />
            <Route
              path="/phic-contribution-report/view"
              element={userToken && !isEmployeeLogin ? <ViewEmployeePhicContribution/> : <Login/>}
            />
            <Route
              path="/phic-contribution-report/print-report"
              element={userToken && !isEmployeeLogin ? <PrintPhicContributionReport/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report"
              element={userToken && !isEmployeeLogin ? <SssContributionReport/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report/view"
              element={userToken && !isEmployeeLogin ? <ViewEmployeeSssContribution/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report/print-report"
              element={userToken && !isEmployeeLogin ? <PrintSssContributionReport/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report"
              element={userToken && !isEmployeeLogin ? <HdmfContributionReport/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report/view"
              element={userToken && !isEmployeeLogin ? <ViewEmployeeHdmfContribution/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report/print-report"
              element={userToken && !isEmployeeLogin ? <PrintHdmfContributionReport/> : <Login/>}
           />

            {/* MANAGE EMPLOYEE MODULES */}
            <Route
              path="/employees"
              element={userToken && !isEmployeeLogin ? <Employee/> : <Login/>}
            />
            <Route
              path="/employees/view"
              element={userToken ? <EmployeeProfile/> : <Login/>}
            />
            <Route
              path="/employees/history-report"
              element={userToken && !isEmployeeLogin ? <EmployeeHistoryReport/> : <Login/>}
            />
            <Route
              path="/employees/face-enrollment"
              element={userToken && !isEmployeeLogin ? <FaceEnrollment/> : <Login/>}
            />
            <Route
              path="/face-attendance"
              element={<FaceAttendance/>}
            />
            <Route
              path="/leave-credits"
              element={userToken ? <LeaveCredit/> : <Login/>}
            />           

      
            {/* MANAGE SYSTEMS */}
            <Route
              path="/business-units"
              element={userToken && !isEmployeeLogin ? <BusinessUnit/> : <Login/>}
            />
            <Route
              path="/departments"
              element={userToken && !isEmployeeLogin ? <Department/> : <Login/>}
            />
            <Route
              path="/letter-types"
              element={userToken && !isEmployeeLogin ? <LetterType/> : <Login/>}
            />
            <Route
              path="/positions"
              element={userToken && !isEmployeeLogin ? <Position/> : <Login/>}
            />
            <Route
              path="/holidays"
              element={userToken && !isEmployeeLogin ? <Holiday/> : <Login/>}
            />
            <Route
              path="/users"
              element={userToken && !isEmployeeLogin  ? <User/> : <Login/>}
            />
          </Routes>
      </Router>
      </TabProvider>
    </div>
  );
}

export default App;
