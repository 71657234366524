import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Table, Modal, Form, Button, DatePicker, Row } from "antd";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  getIsAdminLogin,
  getEmployeeId,
  formatAmount,
} from "../../utils/Common";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Proceed
    </Button>
  );
};

export default function PhicContributionReport() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin ? "" : employeeId,
  });

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Total Employee Share",
      dataIndex: "total_employee_contribution",
      key: "total_employee_contribution",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "Total Employer Share",
      dataIndex: "total_employer_contribution",
      key: "total_employer_contribution",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
    {
      title: "Total Employee Share",
      dataIndex: "total_amount_contributed",
      key: "total_amount_contributed",
      align: "right",
      render: (data) => <div>{formatAmount(data)}</div>,
    },
  ];

  const handleRowClick = (rowData) => {
    navigate("view", { state: rowData });
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const onFinish = (formInputs) => {
    var filter = {}
    filter.date_from = formInputs.date_from.format('YYYY-MM-DD');
    filter.date_to = formInputs.date_to.format('YYYY-MM-DD');
    setShowDatePicker(false);
    navigate("print-report", { state: filter });
  };

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest(
        "contributions/get_philhealth_contribution",
        filterConfig
      );
      if (response.data) {
        setTableData(response.data);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="PhilHealth Contribution"
      noButton
      printButtonTitle="Print Report"
      onClickButton={() => setShowDatePicker(true)}
      isManager
    >
      <Table
        className="mt-4"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
      />

      <Modal
        title="Print PhilHealth Report"
        centereds
        open={showDatePicker}
        onCancel={() => setShowDatePicker(false)}
        footer={false}
      >
        <Form
          form={form}
          className="mt-3 mx-3"
          name="validateOnly"
          layout=""
          onFinish={onFinish}
        >
          <Form.Item
            name="date_from"
            label="Date From"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="date_to"
            label="Date To"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                className="me-2"
                danger
                onClick={() => setShowDatePicker(false)}
              >
                Cancel
              </Button>
              <AddSubmitButton form={form} />
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </Navbar>
  );
}
