import { useState }  from 'react'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getToken, getUser } from '../utils/Common';

const postConfig = {
  headers: {
      "api-key": process.env.REACT_APP_API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
  },
};

const postConfigFormData = {
  headers: {
      "api-key": process.env.REACT_APP_API_KEY,
      'Content-Type': 'multipart/form-data',
  },
};

export const usePostRequest = (onAfterSuccessCall=null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function callPost(endpoint, values, isFormData=false) {
    try {
        setIsLoading(true);
        let payload = isFormData ? new FormData() : {};

        if (isFormData) {
          payload.append("requester", getUser());
          payload.append("token", getToken());

          values.forEach(data => {
            if (Array.isArray(data.value)) {
              // Check if the array contains arrays
              const hasArrays = data.value.some(item => Array.isArray(item));

              if (hasArrays) {
                // If the array contains arrays, append each sub-array as a separate parameter
                data.value.forEach((itemArray, index) => {
                  itemArray.forEach(item => {
                    payload.append(`${data.key}[${index}][]`, item);
                  });
                });
              } else {
                data.value.forEach((item) => {
                  payload.append(`${data.key}[]`, item);
                });
              }
            } else { payload.append(data.key, data.value)}
          })

        } else {
          payload = {
              requester: getUser(),
              token: getToken(),
              ...values,
          }
        }
    
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload,
          isFormData? postConfigFormData : postConfig,
        );

        // if not delete and endpoint
        if (endpoint.split('/')[1] !== 'delete') {
          if ('has_errors' in response.data) {
            if (response.data.has_errors === false) {
              toast.success("Success!")
            } else {
              response.data.errors.forEach(message => {
                toast.error(message)
              })
            }
          } else if (Array.isArray(response.data)){
            response.data.forEach(message => {
              toast.success(message)
            })
          } else {
            if (response.data.data && response.data.data.message) {
              toast.success(response.data.data.message);
            } else if (response.data.response && response.data.response.message) {
              toast.success(response.data.response.message);
            } else if (response.data.message) {
              toast.success(response.data.message);
            } else if (response.data.response) {
              toast.success(response.data.response);
            } else if (response.message) {
              toast.success(response.message);
            } else if (response) {
              toast.success(response)
            } else {
              toast.success('Success');
            }
          }
        }

        setData(response.data);
        setTimeout(()=>{
          if (endpoint.split('/')[1] === 'delete'){
            toast.success("Deleted successfully!")
          }
          onAfterSuccessCall();
        },500)

      } catch (error) {
        if (error?.response?.data?.messages?.error) {
          toast.error(error.response.data.messages.error);
        } else if (error?.response?.data?.messages?.email) {
          toast.error(error.response.data.messages.email)
        } else if (error?.response?.data?.messages?.error_messages) {
          error?.response?.data?.messages?.error_messages?.forEach(errMsg => {
            toast.error(errMsg)
          })
        } else {
          error?.response?.data?.messages?.forEach(errMsg => {
            toast.error(errMsg)
          })
        }
        
        setError(error);
      } finally {
        setIsLoading(false);
      }
  }

  const callPostCopy = callPost;
  return { data, error, isLoading, callPost, callPostCopy };
}
 