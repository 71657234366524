import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Form,
  DatePicker,
  Upload,
  Tabs,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllEmployees,
  GetAllLeaveTypes,
} from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import ReactQuillEditor from "../../components/ReactQuillEditor/ReactQuillEditor";
import {
  getIsAdminLogin,
  getEmployeeId,
  capitalizeEachWord,
} from "../../utils/Common";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

const { Dragger } = Upload;

export default function Leave() {
  const [form] = Form.useForm();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const employeeOptions = GetAllEmployees();
  const leaveTypes = GetAllLeaveTypes();
  const dateFormat = "YYYY-MM-DD";
  const [searchTerm, setSearchTerm] = useState("");

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: window.$isEmployeeLogin ? employeeId : "",
    status: "pending",
  });

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Date Applied",
      dataIndex: "added_on",
      key: "added_on",
      render: (added_on, record) => (
        <div>{moment(added_on).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <div>{moment(date).format("MMM DD, YYYY")}</div>
      ),
    },

    {
      title: "Leave Type",
      dataIndex: "leave_type_name",
      key: "leave_type_name",
      render: (data) => <div>{capitalizeEachWord(data)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => <div>{capitalizeEachWord(data)}</div>,
    },
  ];

  const onFinish = async (formInputs) => {
    formInputs.date = formInputs.date.format(dateFormat);
    formInputs.status = "pending";
    formInputs.is_half_day = formInputs.is_half_day?"1":"0";
    formInputs.file_names = [];

    callPost(
      "employee_leaves/add",
      formInputs,
      false
    );
  };

  const onEdit = async (formInputs) => {
    formInputs.employee_leave_id = clickedRowData.id;
    formInputs.date = formInputs.date.format(dateFormat);
    formInputs.status = clickedRowData.status;
    formInputs.is_half_day = formInputs.is_half_day ? "1" : "0";

    // const tempFiles = fileList.map(async (file) => {
    //     var tempFile = await getBase64(file);
    //     return tempFile;
    // });
    // const tempPromise = await Promise.all(tempFiles);
    formInputs.file_names = [];
    callPost(`employee_leaves/update`, formInputs, false);
  };

  const confirmApprove = (leave, status) => {
    modal.confirm({
      title: `Are you sure you want to ${status} ${leave.employee_name}'s ${leave.leave_type_name}?`,
      icon: <CheckCircleOutlined />,
      //   content: "You cannot undo this action.",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApprove(leave.id, status),
      //   okType: "danger",
    });
  };

  function handleApprove(passedId, status) {
    const newStatus = `${status}d`;
    callPost(
      "employee_leaves/approve",
      { employee_leave_id: passedId, status: newStatus },
      false
    );
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  }

  const confirmDelete = (leave) => {
    modal.confirm({
      title: `Are you sure you want to delete this leave?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(leave.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("employee_leaves/delete", { employee_leave_id: passedId }, false);
    resetValues();
  }

  function resetValues() {
    form.resetFields();
    setContent("");
    setFileList([]);
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  const handleRowClick = (rowData) => {
    if (window.$isEmployeeLogin) {
      navigate("view", { state: rowData });
    } else {
      setContent(rowData.remarks);
      setClickedRowData(rowData);
      const formattedValues = { ...rowData };
      formattedValues.date = formattedValues.date
        ? dayjs(formattedValues.date, dateFormat)
        : "";
      formattedValues.is_half_day =
        formattedValues.is_half_day && formattedValues.is_half_day === "1"
          ? true
          : false;
      form.setFieldsValue(formattedValues);
      setShowViewModal(true);
    }
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const handleQuillChange = (html) => {
    setContent(html);
    form.setFieldValue("remarks", html);
  };

  // React.useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(searchTimeout);
  //   };
  // }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest(
        "employee_leaves/get",
        filterConfig
      );
      if (response.data) {
        setTableData(response.data);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Leaves"
      pageButtonTitle="Add Leave"
      onClickButton={() => {
        setShowAddModal(true);
        setShowViewModal(false);
      }}
      searchPlaceholder="employee"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      isManager
      searchable={isAdminLogin}
    >
      <Tabs
        defaultActiveKey="pending"
        className="mt-4"
        items={[
          { key: "pending", label: "Pending" },
          { key: "approved", label: "Approved" },
          { key: "disapproved", label: "Disapproved" },
          { key: "", label: "All" },
        ]}
        type="card"
        onChange={(e) => setFilterConfig((prev) => ({ ...prev, status: e }))}
      />
      <Table
        className=""
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} Leave`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
          initialValues={{is_half_day: false, employee_id: window.$isEmployeeLogin ? employeeId : ""}}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={employeeOptions.slice(1)}
                  showSearch
                  filterOption={(input, option) =>
                    option.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="leave_type"
                label="Leave Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={leaveTypes.slice(1)} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="center">
              <Form.Item
                name="is_half_day"
                valuePropName="checked"
                className="ms-4 mt-3"
              >
                <Checkbox className="me-2">Half Day</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item name="remarks" label="Remarks">
                <ReactQuillEditor
                  content={content}
                  handleQuillChange={handleQuillChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Leave"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="leave_type"
                label="Leave Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={leaveTypes.slice(1)} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="is_half_day" valuePropName="checked" className="ms-4 mt-3">
              <Checkbox className="me-2" disabled>Half Day</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item name="remarks" label="Remarks">
                <ReactQuillEditor
                  content={content}
                  handleQuillChange={handleQuillChange}
                  isDisabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={clickedRowData.status === "approved"}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData, "approve");
                }}
                icon={<CheckCircleOutlined />}
                disabled={clickedRowData.status === "approved"}
              >
                Approve
              </Button>
              {isAdminLogin && (
                <Button
                  type="text"
                  onClick={() => {
                    confirmApprove(clickedRowData, "disapprove");
                  }}
                  icon={<CloseCircleOutlined />}
                  disabled={clickedRowData.status === "disapproved"}
                  style={{ color: "red" }}
                >
                  Disapprove
                </Button>
              )}
              <Button
                type="text"
                onClick={() => {
                  navigate("view", { state: clickedRowData });
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={clickedRowData.status === "approved"}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
