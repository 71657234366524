import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Divider, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import Title from "antd/es/typography/Title";
import Navbar from "../../components/Navbar/Navbar";
import logo from "../../assets/images/logo.png";
import { capitalizeEachWord, formatDate } from "../../utils/Common";
import ReactToPrint from "react-to-print";

export default function ViewCommunicationLetter() {
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const passedData = location.state;

  return (
    <Navbar isForm>
      <Container className="p-4 view-page">
        <div ref={componentRef}>
          <Row>
          <Col xs={3} className="me-3">
              <img src={logo} alt="logo" className="view-page-logo" />
            </Col>
            <Col>
              <Row>
                <Title level={3}>{window.$company_name}</Title>
              </Row>
              <Row>{window.$company_address}</Row>
            </Col>
          </Row>
          <Divider />
          <Row className="center my-3">
            <Title level={4}>LETTER NO. {passedData.code}</Title>
          </Row>
          <Row>
            <Col md={3} lg={2}>Date</Col>
            <Col xs={1}>:</Col>
            <Col>{formatDate(passedData.letter_date)}</Col>
          </Row>
          <Row>
            <Col md={3} lg={2}>To</Col>
            <Col xs={1}>:</Col>
            <Col>
              {(function () {
                let to = passedData.details
                  ? passedData.details.map((t) => t.letter_to)
                  : [];
                return to.join(", ");
              })()}
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={2}>From</Col>
            <Col xs={1}>:</Col>
            <Col>{capitalizeEachWord(passedData.letter_from)}</Col>
          </Row>
          <Row>
            <Col md={3} lg={2}>Subject</Col>
            <Col xs={1}>:</Col>
            <Col>{capitalizeEachWord(passedData.subject)}</Col>
          </Row>
          <Divider />
          <Row className="px-5 my-4 center">
            <div
              dangerouslySetInnerHTML={{
                __html: passedData.content ? passedData.content : "",
              }}
            />
          </Row>
          <Divider />
          <Row className="">
                  <Col xs={24} sm={12} md={12} className="mt-5">
                      <Row className="ms-3">Prepared by:</Row>
                      <Row className="mt-5 center uppercase">{passedData?.added_by_name}</Row>
                      <Divider/>
                      <Row className="mt-2 center text-center">{passedData?.added_by_position}</Row>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="mt-5">
                      <Row className="ms-3">Approved by:</Row>
                      <Row className="mt-5 center uppercase">{passedData?.approved_by_name}</Row>
                      <Divider/>
                      <Row className="mt-2 center text-center">{passedData?.approved_by_position}</Row>
                  </Col>
              </Row>
        </div>

        <Row className="d-flex justify-content-end noPrint">
          <Row>
            <Button
              className="cancel-btn me-2"
              onClick={() => navigate("/communication-letters")}
            >
              Cancel
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined /> Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Row>
        </Row>
      </Container>
    </Navbar>
  );
}
