import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Card,
  Col,
  Typography,
  Divider,
  Table,
  Modal,
  Button,
} from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import Navbar from "../../components/Navbar/Navbar";
import tempImg from "../../assets/images/user-profile.jpg";
import { UseGetRequest, UsePostRequest } from "../../utils/api/ApiRequests"; // used to GET data with that uses 'post'
import moment from "moment";
import { saveAs } from "file-saver";
import {
  capitalizeEachWord,
  convertContractToInternational,
  formatAmount,
  formatDate,
  getEmployeeId,
} from "../../utils/Common";

const { Title } = Typography;

export default function EmployeeProfile() {
  const location = useLocation();
  const employeeId = getEmployeeId();
  const passedId = location?.state?.passedId
    ? location.state.passedId
    : employeeId;
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [employmentHistory, setEmploymentHistory] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [previewFile, setPreviewFile] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const employmentHistoryTable = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (text, record) =>
        text ? moment(text).format("MMMM D, yyyy") : "",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      render: (text) => (text ? formatAmount(text) : ""),
    },
  ];

  const requirementsTable = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Attachments",
      dataIndex: "files",
      key: "files",
      render: (data, record) => (
        <div>
          {data ? (
            data.map((file) => (
              <Row className="">
                <PaperClipOutlined />
                <div
                  className="pointer-cursor ms-2 smaller-text"
                  onClick={() => handleFilePreview(file)}
                >
                  {file.file_name}
                </div>
              </Row>
            ))
          ) : (
            <Row className="smaller-text">No Attachments</Row>
          )}
        </div>
      ),
    },
  ];

  function formatTinNumber(input) {
    // Convert input to a string
    if (input) {
      const inputString = String(input);

      const formattedString = inputString.replace(
        /(\d{1,3})(?=(\d{3})+(?!\d))/g,
        "$1-"
      );
      return formattedString;
    }
    return null;
  }

  function handleFilePreview(file) {
    file.file_url = file.url;
    setPreviewFile(file);
    const fileType = file.file_name.split(".");
    setSelectedFileType(fileType[1]);
    setShowPreview(true);
  }

  function handleDownload() {
    saveAs(previewFile.file_url, previewFile.file_name);
  }

  React.useEffect(() => {
    async function fetchEmployeeData() {
      const response = await UsePostRequest("employees/get", {
        employee_id: passedId,
      });
      if (response.data) {
        const res = response.data[0];
        const tempName = `${res.first_name} ${res.middle_name} ${res.last_name}`;
        res.fullname = capitalizeEachWord(tempName);
        setEmployeeDetails(res);
      }
    }

    async function fetchEmploymentHistory() {
      const response = await UsePostRequest(
        "employees/employment_history/" + passedId
      );
      setEmploymentHistory([]);
      if (response.data) {
        const res = response.data;
        const result = res.map((data, index) => {
          var info = [];
          info.period = data.doc_date;
          info.salary = data.value_to;
          info.position = data.position_to;
          return info;
        });
        setEmploymentHistory(result);
      }
    }

    async function fetchRequirements() {
      setRequirements([]);
      const response = await UsePostRequest(
        "employees/get_employee_requirements",
        { employee_id: passedId }
      );
      if (response.response) {
        const res = response.response.file_url;
        const tempAttachments = [
          {
            type: "Health Certificate",
            files: res.health_certificate,
          },
          {
            type: "Barangay Clearance",
            files: res.barangay_clearance,
          },
          {
            type: "Police Clearance",
            files: res.police_clearance,
          },
          {
            type: "NBI Clearance",
            files: res.nbi_clearance,
          },
          {
            type: "TOR",
            files: res.tor,
          },
          {
            type: "Solo Parent",
            files: res.solo_parent,
          },
        ];
        setRequirements(tempAttachments);
      }
    }

    async function fetchProfilePicture() {
      const response = await UseGetRequest("employees/get_employee_picture", {
        employee_id: passedId,
      });
      if (response.data) {
        setFileUrl(response.data.file_url);
      } else {
        setFileUrl(null);
      }
    }

    fetchEmployeeData();
    fetchEmploymentHistory();
    fetchRequirements();
    fetchProfilePicture();
  }, []);

  return (
    <Navbar formTitle="Employee Profile" isForm={!window.$isEmployeeLogin}>
      <Row className="view-page">
        <Col lg={4} xs={24}>
          <Card className="profile-pic-container">
          <img
              src={fileUrl ? fileUrl : tempImg}
              alt=""
              className="profile-picture"
              width={350}
              height={350}
            />
          </Card>
        </Col>
        <Col lg={20} xs={24}>
          <Card className="mx-1">
            <Row>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={5} className="bold">
                    Name
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>{`${employeeDetails.last_name}, ${
                    employeeDetails.first_name
                  } ${
                    employeeDetails.middle_name
                      ? ", " + employeeDetails.middle_name
                      : ""
                  } ${
                    employeeDetails.suffix ? ", " + employeeDetails.suffix : ""
                  }`}</Col>
                </Row>
                <Row>
                  <Col lg={5} className="bold">
                    Religion
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>
                    {capitalizeEachWord(employeeDetails.religion)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} className="bold">
                    Civil Status
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>
                    {capitalizeEachWord(employeeDetails.civil_status)}
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={5} className="bold">
                    Gender
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>
                    {capitalizeEachWord(employeeDetails.gender)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} className="bold">
                    Birthdate
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>
                    {moment(employeeDetails.birthdate).format("MM-DD-YY")}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} className="bold">
                    Nationality
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={17}>
                    {capitalizeEachWord(employeeDetails.nationality)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col lg={12} xs={24}>
              <Card className="mt-2 mx-1">
                <Title level={5}>Contact Details</Title>
                <Divider />
                <Row>
                  <Col lg={7} className="bold">
                    Contact No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>
                    {convertContractToInternational(employeeDetails.contact_no)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} className="bold">
                    Email Address
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>{employeeDetails.email}</Col>
                </Row>
                <Row>
                  <Col lg={7} className="bold">
                    Address
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>{employeeDetails.address}</Col>
                </Row>
              </Card>
            </Col>
            <Col lg={12} xs={24}>
              <Card className="mt-2 mx-1">
                <Title level={5}>Emergency Contact</Title>
                <Divider />
                <Row>
                  <Col lg={7} className="bold">
                    Contact Person
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>
                    {capitalizeEachWord(employeeDetails.contact_person)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} className="bold">
                    Relationship
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>
                    {capitalizeEachWord(
                      employeeDetails.contact_person_relationship
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} className="bold">
                    Contact No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>
                    {convertContractToInternational(
                      employeeDetails.contact_number
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} className="bold">
                    Address
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15}>
                    {capitalizeEachWord(employeeDetails.contact_address)}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Card className="mx-1 mt-2">
            <Title level={5}>Family Relationship Details</Title>
            <Divider />
            <Row>
              <Col lg={3} md={6} xs={11} className="bold">
                Mother's Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6} md={17} xs={12}>
                {capitalizeEachWord(employeeDetails.mothers_name)}
              </Col>
              <Col lg={1} md={3} xs={3} className="bold">
                Age
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={2} xs={3}>
                {employeeDetails.mothers_age}
              </Col>
              <Col lg={2} className="bold">
                Occupation
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>
                {capitalizeEachWord(employeeDetails.mothers_occupation)}
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={6} xs={11} className="bold">
                Father's Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6} md={17} xs={12}>
                {capitalizeEachWord(employeeDetails.fathers_name)}
              </Col>
              <Col lg={1} md={3} xs={3} className="bold">
                Age
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={2} xs={3}>
                {employeeDetails.fathers_age}
              </Col>
              <Col lg={2} className="bold">
                Occupation
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>
                {capitalizeEachWord(employeeDetails.fathers_occupation)}
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={6} xs={11} className="bold">
                Spouse's Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6} md={17} xs={12}>
                {capitalizeEachWord(employeeDetails.spouse_name)}
              </Col>
              <Col lg={1} md={3} xs={3} className="bold">
                Age
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={2} xs={3}>
                {employeeDetails.spouse_age}
              </Col>
              <Col lg={2} className="bold">
                Occupation
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>
                {capitalizeEachWord(employeeDetails.spouse_occupation)}
              </Col>
            </Row>
            {employeeDetails.children &&
              employeeDetails.children.map((row) => (
                <Row>
                  <Col lg={3} md={6} xs={11} className="bold">
                    Child Name
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} md={17} xs={12}>
                    {capitalizeEachWord(row.name)}
                  </Col>
                  <Col lg={1} md={3} xs={3} className="bold">
                    Age
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={2} xs={3}>
                    {row.age}
                  </Col>
                  <Col lg={2} className="bold">
                    Occupation
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6}>{capitalizeEachWord(row.occupation)}</Col>
                </Row>
              ))}
          </Card>
          <Row>
            <Col lg={12} xs={24}>
              <Card className="mt-2 mx-1">
                <Title level={5}>
                  Employee Benefit Contribution Information
                </Title>
                <Divider />
                <Row>
                  <Col lg={6} className="bold">
                    SSS No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {employeeDetails.sss}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="bold">
                    HDMF No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {employeeDetails.hdmf}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="bold">
                    PhilHealth No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {employeeDetails.phic}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="bold">
                    HMO No.
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {employeeDetails.hmo_number}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="bold">
                    Insurance
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {employeeDetails.insurance_number}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={12} xs={24}>
              <Card className="mt-2 mx-1">
                <Title level={5}>Withholding Tax Information</Title>
                <Divider />
                <Row>
                  <Col lg={6} className="bold">
                    TIN
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={15} className="ms-2">
                    {formatTinNumber(employeeDetails.tin)}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Card className="mx-1 mt-2">
            <Title level={5}>Employment Information</Title>
            <Divider />
            <Row>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    DTR ID
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.dtr_id}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Position
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.position_name}
                  </Col>
                </Row>
                {
                  !window.$isEncoderLogin && <Row>
                  <Col lg={8} xs={7} className="bold">
                    Salary
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    PHP{" "}
                    {employeeDetails.salary
                      ? formatAmount(employeeDetails.salary[0]?.salary_to)
                      : ""}
                  </Col>
                </Row>
                }
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Salary Type
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.salary
                      ? capitalizeEachWord(
                          employeeDetails.salary[0]?.salary_type
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Bank
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.bank}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Bank Account Name
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.bank_account_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Bank Account Number
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.bank_account_number}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Employment Status
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.employment_status_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Business Unit
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.business_unit_name}
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Department
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.department_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Immediate Head
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.immediate_head_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Current Shift
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.shift_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Date Hired
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {formatDate(employeeDetails.date_hired)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    End of Contract
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.end_of_contract
                      ? formatDate(employeeDetails.end_of_contract)
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Date Regularized
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.date_regularized
                      ? formatDate(employeeDetails.date_regularized)
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Date Terminated/Resigned
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.date_terminated_resigned
                      ? formatDate(employeeDetails.date_terminated_resigned)
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} xs={7} className="bold">
                    Previous Employer
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={14} className="ms-2">
                    {employeeDetails.previous_employer}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          {       
            !window.$isEncoderLogin && <Card className="mx-1 mt-2">
            <Title level={5}>Allowances</Title>
            <Divider />
            <Row>
              <Col lg={24} xs={24}>
                <Row>
                  <Col lg={8} md={7} xs={14} className="bold">
                    Monthly
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={7} className="ms-2">
                    {employeeDetails.allowance
                      ? formatAmount(
                          employeeDetails.allowance[0]?.daily_allowance
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={7} xs={14} className="bold">
                    Communication
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={7} className="ms-2">
                    {employeeDetails.allowance
                      ? formatAmount(
                          employeeDetails.allowance[0]?.commu_allowance
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={7} xs={14} className="bold">
                    Transportation
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={7} className="ms-2">
                    {employeeDetails.allowance
                      ? formatAmount(
                          employeeDetails.allowance[0]?.transpo_allowance
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={7} xs={14} className="bold">
                    Food
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={7} className="ms-2">
                    {employeeDetails.allowance
                      ? formatAmount(
                          employeeDetails.allowance[0]?.food_allowance
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={7} xs={14} className="bold">
                    Uniform
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={14} xs={7} className="ms-2">
                    {employeeDetails.allowance
                      ? formatAmount(
                          employeeDetails.allowance[0]?.uniform_allowance
                        )
                      : ""}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card> 
          }
          <div className="noPrint">
            <Card className="mx-1 mt-2">
              <Title level={5} className="">
                Uploaded Requirements
              </Title>
              <Divider />
              <Table
                dataSource={requirements}
                columns={requirementsTable}
                pagination={false}
              />
            </Card>
          </div>
          {
            !window.$isEncoderLogin && <Card className="mx-1 mt-2">
            <Title level={5}>Employment History</Title>
            <Divider />
            <Table
              dataSource={employmentHistory}
              columns={employmentHistoryTable}
            />
          </Card>
          }
        </Col>
      </Row>

      <Modal
        open={showPreview}
        onCancel={() => setShowPreview(false)}
        footer={null}
        closeIcon={false}
      >
        <Row>
          <Col xs={24}>
            {selectedFileType === "pdf" ? (
              <iframe
                src={previewFile.file_url}
                className="pdf-preview"
                width="100%"
                height="600px"
                title={previewFile}
              />
            ) : (
              <>
                <img
                  src={previewFile.file_url}
                  alt="img-preview"
                  className="w-100"
                />
                <Row className="justify-content-end mt-3">
                    <Button onClick={()=>setShowPreview(false)} className="me-2">Close</Button>
                  <Button type="primary" onClick={() => handleDownload()}>
                    Download
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </Navbar>
  );
}
