import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Table, Space, Select } from "antd";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  capitalizeEachWord,
  formatNumber,
  getIsAdminLogin,
  getEmployeeId,
} from "../../utils/Common";
import {
  GetAllDepartments,
  GetAllEmploymentStatus,
  GetAllPositions,
} from "../../utils/api/DropdownFiltersApi";
import { useTheme, useMediaQuery } from "@mui/material";
import { removeUnderscoreToSentence } from "../../utils/Common";

export default function ThirteenthMonth() {
  // Get the screen size
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dateToday = new Date();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [verticalTableData, setVerticalTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const statusOptions = GetAllEmploymentStatus();
  const positionOptions = GetAllPositions();
  const departmentOptions = GetAllDepartments();
  const [searchTerm, setSearchTerm] = useState(null);

  const [filterConfig, setFilterConfig] = useState({
    employee_id: isAdminLogin ? null : employeeId,
    year: dateToday.getFullYear(),
  });

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (employee_name) => <div>{capitalizeEachWord(employee_name)}</div>,
    },
    {
      title: "Jan",
      dataIndex: "january",
      key: "january",
      align: "right",
      render: (january, record) => <div>{formatNumber(january)}</div>,
    },
    {
      title: "Feb",
      dataIndex: "february",
      key: "february",
      align: "right",
      render: (february, record) => <div>{formatNumber(february)}</div>,
    },
    {
      title: "Mar",
      dataIndex: "march",
      key: "march",
      align: "right",
      render: (march, record) => <div>{formatNumber(march)}</div>,
    },
    {
      title: "Apr",
      dataIndex: "april",
      key: "april",
      align: "right",
      render: (april, record) => <div>{formatNumber(april)}</div>,
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
      align: "right",
      render: (may, record) => <div>{formatNumber(may)}</div>,
    },
    {
      title: "Jun",
      dataIndex: "june",
      key: "june",
      align: "right",
      render: (june, record) => <div>{formatNumber(june)}</div>,
    },
    {
      title: "Jul",
      dataIndex: "july",
      key: "july",
      align: "right",
      render: (july, record) => <div>{formatNumber(july)}</div>,
    },
    {
      title: "Aug",
      dataIndex: "august",
      key: "august",
      align: "right",
      render: (august, record) => <div>{formatNumber(august)}</div>,
    },
    {
      title: "Sep",
      dataIndex: "september",
      key: "september",
      align: "right",
      render: (september, record) => <div>{formatNumber(september)}</div>,
    },
    {
      title: "Oct",
      dataIndex: "october",
      key: "october",
      align: "right",
      render: (october, record) => <div>{formatNumber(october)}</div>,
    },
    {
      title: "Nov",
      dataIndex: "november",
      key: "november",
      align: "right",
      render: (november, record) => <div>{formatNumber(november)}</div>,
    },
    {
      title: "Dec",
      dataIndex: "december",
      key: "december",
      align: "right",
      render: (december, record) => <div>{formatNumber(december)}</div>,
    },
  ];

  const keyValuePairColumns = [
    {
      title: "",
      dataIndex: "header",
      key: "header",
      render: (data) => (
        <div className="bold">{removeUnderscoreToSentence(data)}</div>
      ),
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({ ...prev, [field]: e }));
  };

  // React.useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(searchTimeout);
  //   };
  // }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest(
        "payrolls/thirteenth_month_report",
        filterConfig
      );
      if (response.data) {
        setTableData(response.data);

        // CREATE KEY-VALUE-PAIR TABLE DATA FOR VERTICAL TABLE
        // create a new array of object containing header and value
        const tempTableData = response.data.map((row) =>
          Object.entries(row).map(([header, value]) => ({
            header,
            value,
            ...row,
          }))
        );
        // extract only the objects we need for the table
        const filteredDataSource = tempTableData
          .flat()
          .filter((record) =>
            tableColumns.some((column) => column.dataIndex === record.header)
          );
        setVerticalTableData(filteredDataSource);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Thirteenth Month Report"
      noButton
      searchable={isAdminLogin}
      searchPlaceholder="employee"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      isManager
      withYearFilter
      onYearChange={(e) => {
        if (e) {
          setFilterConfig((prev) => ({ ...prev, year: e.$y }));
        } else {
          setFilterConfig((prev) => ({ ...prev, year: null }));
        }
      }}
    >
      {isAdminLogin && (
        <Space className="mt-4 d-flex justify-content-start" wrap>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Status"
            optionFilterProp="items"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) => {
              if (optionA.label?.includes("All")) return -1;
              if (optionB.label?.includes("All")) return 1;
              return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
            }}
            options={statusOptions}
            onChange={(e) => handleFilter(e, "employment_status_id")}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Position"
            optionFilterProp="items"
            filterOption={(input, option) => option.label.includes(input)}
            filterSort={(optionA, optionB) => {
              if (optionA.label?.includes("All")) return -1;
              if (optionB.label?.includes("All")) return 1;
              return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
            }}
            options={positionOptions}
            onChange={(e) => handleFilter(e, "position_id")}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Department"
            optionFilterProp="items"
            filterOption={(input, option) => option.label.includes(input)}
            filterSort={(optionA, optionB) => {
              if (optionA.label?.includes("All")) return -1;
              if (optionB.label?.includes("All")) return 1;
              return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
            }}
            options={departmentOptions}
            onChange={(e) => handleFilter(e, "department_id")}
          />
        </Space>
      )}

      <Table
        className={isAdminLogin ? "" : "mt-4"}
        rowKey="id"
        columns={isPhoneScreen ? keyValuePairColumns : tableColumns}
        dataSource={isPhoneScreen ? verticalTableData : tableData}
        loading={isFetching}
        pagination={{ defaultPageSize: 13 }}
      />
    </Navbar>
  );
}
