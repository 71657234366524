import React, {useRef, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Button } from "antd";
import Title from "antd/es/typography/Title";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import NewLogo from "../../assets/images/logo.png";
import { capitalizeEachWord, formatAmount, formatDate, removeUnderscoreToSentence } from "../../utils/Common";
import ReactToPrint from "react-to-print";
import {PrinterOutlined} from "@ant-design/icons";
import { UsePostRequest } from "../../utils/api/ApiRequests";


export default function ViewLoan () {
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state;
    const dateToday = new Date();
    const [paymentSummary, setPaymentSummary] = useState([])
    const [paymentHistory, setPaymentHistory] = useState([]);


    React.useEffect(() => {
        async function fetchLoanData () {
            setPaymentHistory([]);
            setPaymentSummary([]);
            const response = await UsePostRequest("employee_loans/get_loan_history", {loan_id: passedData.id})
            if (response.response) {
                setPaymentSummary(response.response.employee_history.overview);
                setPaymentHistory(response.response.employee_history.loan_history);
            }
        }

        fetchLoanData();
    }, [passedData]);

    return (
        <Navbar>
            <Container className='print-report-page p-3 view-page'>
                <div  ref={componentRef}>
                <Row className='d-flex justify-content-end'><Title level={3}>LOAN</Title></Row>
                <Row className='d-flex justify-content-end'>Date Printed: {formatDate(dateToday)}
                </Row>
                <Row className='px-1 ms-2'><img src = {NewLogo} alt={"logo"} className="payslip-logo"/></Row>
                <Row className='bold p-1'>{window.$company_name}</Row>
                <Row className='p-1'>{window.$company_address}</Row>
                <Row className='p-1 mt-3'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='primary-bg white-text '>
                                <th colSpan={2} className="">Loan Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bold w-50'>Employee Name</td>
                                <td>{passedData.employee_name ? capitalizeEachWord(passedData.employee_name) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Issue Date</td>
                                <td>{formatDate(passedData.issue_date)}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Loan Type</td>
                                <td>{removeUnderscoreToSentence(passedData.loan_type)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                
                <Row className='mt-4'>
                <Row className='py-2'><Title level={5}>PAYMENT SUMMARY</Title></Row>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='primary-bg white-text'>
                                <th className="text-end">Loan Amount</th>
                                <th className="text-end">Paid Amount</th>
                                <th className="text-end">Remaning Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                paymentSummary && paymentSummary.map((data) => (
                                    <tr>
                                        <td className='text-end'>Php {formatAmount(data.loan_amount)}</td>
                                        <td className='text-end'>Php {formatAmount(data.paid_amount)}</td>
                                        <td className='text-end'>Php {formatAmount(data.remaining_balance)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Row>

                <Row className='mt-3'>
                <Row className='py-2'><Title level={5}>PAYMENT HISTORY</Title></Row>
                    <table className='table table-bordered cont-history-wrapper'>
                        <thead>
                            <tr className='primary-bg white-text'>
                                <th>Year</th>
                                <th>Month</th>
                                <th className="text-end">Paid Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (paymentHistory && paymentHistory.length) ? (
                                    paymentHistory.map((data) => {
                                    return (
                                        <tr>
                                            <td>{data.year}</td>
                                            <td>{data.month}</td>
                                            <td className="text-end">Php {formatAmount(data.loan_payment)}</td>
                                        </tr>
                                    )
                                    })
                                ) : ('')
                            }
                        </tbody>
                    </table>
                </Row>

                <Row className="d-flex justify-content-end my-4 noPrint">
                    <Row className="">
                        <Button className="me-2" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <ReactToPrint
                            trigger={() => (
                                <Button type="primary"><PrinterOutlined/>Print</Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"Pag-IBIG"}
                        />  
                    </Row>
                </Row>
                </div>
            </Container>
        </Navbar>
    )
}