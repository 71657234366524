import React, {useState, useRef} from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Table, Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import ReactToPrint from "react-to-print";
import {UsePostRequest} from "../../utils/api/ApiRequests";


export default function PrintHdmfContributionReport () {
    const location = useLocation();
    const componentRef = useRef();
    // const [isFetching, setIsFetching] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totals, setTotals] = useState({});
    const [filterConfig, setFilterConfig] = useState({
        date_from: location.state.date_from,
        date_to: location.state.date_to,
    });

    React.useEffect(() => {
        async function fetchTableData () {
            // setIsFetching(true);
            setTableData([]);
            setTotals({});
            const response = await UsePostRequest("contributions/get_pagibig_report", filterConfig);
            if (response.data) {
                setTableData(response.data);
                setTotals(response.response.grand_total);
            }
            // setIsFetching(false);
        }

        fetchTableData();
    }, [filterConfig])

    return (
        <Navbar isForm>
            <Container className="print-report-page p-3 view-page">
                <div ref={componentRef}>
                <Row className="mt-3">
                    <h4 className="">MEMBER SAVINGS - View Remittance list</h4>
                </Row>

                <div className="mt-2">
                    <Table className="table-header-only-borders stripe-table">
                        <thead>
                            <tr className="text-center">
                                <th>Pag-IBIG MID No.</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Employee Share</th>
                                <th>Employer Share</th>
                                <th>Total Contribution</th>
                                <th>Month Covered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (tableData && tableData.length) ? (
                                    tableData.map((row, index) => {
                                        return (
                                            <tr>
                                                <td className="text-center">{row.hdmf}</td>
                                                <td>{row.last_name}</td>
                                                <td>{row.first_name}</td>
                                                <td>{row.middle_name}</td>
                                                <td className="text-end">{row.employee_contribution}</td>
                                                <td className="text-end">{row.employer_contribution}</td>
                                                <td className="text-end">{row.total_contribution}</td>
                                                <td className="text-end">{row.month +', '+row.year}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="text-center">
                                        <td colSpan={8}>No Data Found</td>
                                    </tr>
                                )
                            }
                        </tbody>
                        <tbody className="bordered-body">
                            <tr>
                                <td className="bold">Record Count: {tableData.length}</td>
                                <td colSpan={3} className="text-end bold">Total Amount: </td>
                                <td className="text-end">Php {totals.total_employee_contribution ? Number(totals.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                <td className="text-end">Php {totals.total_employer_contribution ? Number(totals.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                <td className="text-end">Php {totals.total_total ? Number(totals.total_total).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                <td className="text-end"></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                    <Row className="d-flex justify-content-center mt-4 mt-5 mb-5 noPrint">
                        <Col className="d-flex justify-content-center print">
                        <ReactToPrint
                            trigger={() => (
                            <Button type="primary">
                                <PrinterOutlined /> Print
                            </Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"Pag-Ibig Report"}
                        />
                        </Col>
                    </Row>

                </div>
            </Container>
        </Navbar>
    )
}