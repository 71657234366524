import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Modal, Table, Row, Col, DatePicker, Select, Input, Tabs, InputNumber } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, MoneyCollectOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import dayjs from 'dayjs'
import { GetAllEmployees } from '../../utils/api/DropdownFiltersApi'
import TextArea from 'antd/es/input/TextArea'
import { formatAmount, formatDate, removeUnderscoreToSentence, getIsAdminLogin, getEmployeeId, capitalizeEachWord, extractAmount } from '../../utils/Common'
import toast from 'react-hot-toast'


const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
      React.useEffect(() => {
        form.validateFields({
          validateOnly: true,
        }).then(() => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
        );
      }, [values]);

      return (
        <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
      );
  };

export default function Loan() {
  const [form] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const navigate = useNavigate();
  const employeeOptions = GetAllEmployees();
  const dateFormat = 'YYYY-MM-DD';
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();

  
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    setShowPaymentModal(false);
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}));
    resetFormFields();
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [searchTerm, setSearchTerm] = useState(null);

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: window.$isEmployeeLogin ? employeeId : "",
    status: "pending"
  });

  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
        title: 'Employee',
        dataIndex: 'employee_name',
        key: 'employee_name',
    },
    {
        title: 'Loan Type',
        dataIndex: 'loan_type',
        key: 'loan_type',
        render: (loan_type) => (
          <div>{removeUnderscoreToSentence(loan_type)}</div>
        )
    },
    {
        title: 'Issue Date',
        dataIndex: 'issue_date',
        key: 'issue_date',
        render: (issue_date) => (
          <div>{formatDate(issue_date)}</div>
        )
    },
    {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        align: 'right',
        render: (loan_amount) => (
          <div>{formatAmount(loan_amount)}</div>
        )
    },
    {
        title: 'Paid Amount',
        dataIndex: 'paid_amount',
        key: 'paid_amount',
        align: 'right',
        render: (paid_amount) => (
          <div>{formatAmount(paid_amount)}</div>
        )
    },
    {
        title: 'Remaining Balance',
        dataIndex: 'remaining_balance',
        key: 'remaining_balance',
        align: 'right',
        render: (remaining_balance) => (
          <div>{formatAmount(remaining_balance)}</div>
        )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => <div>
        {capitalizeEachWord(data)}
      </div>
    },
  ];

  const loanTypes = [
    {value: 'sss_salary_loan', label: 'SSS Salary Loan'},
    {value: 'sss_calamity_loan', label: 'SSS Calamity Loan'},
    {value: 'multipurpose_loan', label: 'Multipurpose Loan'},
    {value: 'company_loan', label: 'Company Loan'},
    {value: 'pagibig_loan', label: 'PagIBIG Loan'},
    {value: 'calamity_loan', label: 'Calamity Loan'},
    {value: 'others', label: 'Others'}
];

const termTypes = [
    {value: 'per_payroll', label: 'Per Payroll'},
    {value: 'end_month', label: 'End Month'},
];

  const onFinish = (formInputs) => {
    formInputs.issue_date = formInputs.issue_date.format(dateFormat);
    callPost(
      "employee_loans/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.issue_date = formInputs.issue_date.format(dateFormat);
    formInputs.employee_loan_id = clickedRowData.id;
    callPost(
      `employee_loans/update`, 
      formInputs,
      false
    );
  };

  const onSavePayment = (formInputs) => {
    if (Number(parseFloat(formInputs.paid_amount).toFixed(2)) > Number(parseFloat(extractAmount(formInputs.balance)).toFixed(2))) {
      toast.error("Paid amount cannot exceed remaining balance")
    } else {
      callPost("employee_loans/update_loan_payment", {...formInputs, employee_loan_id: clickedRowData.id}, false);
    }
  }

  const confirmApprove = (loan, status) => {
    const newStatus = `${status}d`
    modal.confirm({
      title: `Are you sure you want to ${status} this loan?`,
      icon: <CheckCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApprove(loan.id, newStatus),
    });
  };

  function handleApprove(passedId, newStatus) {
    callPost("employee_loans/update_status", { employee_loan_id: passedId, status: newStatus }, false);
  }

  const confirmDelete = (loan) => {
    modal.confirm({
      title: `Are you sure you want to delete this loan?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(loan.id),
      okType: 'danger',
    });
  };


  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
    setFilterConfig((prev) => ({...prev, term: e}));
  }

  function handleDelete(passedId) {
    callPost("employee_loans/delete",  {employee_loan_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    if (window.$isEmployeeLogin) {
      navigate("view", {state: rowData})
    } else {
      setClickedRowData(rowData);
      const formattedValues = { ...rowData };
      formattedValues.issue_date = rowData.issue_date?dayjs(rowData.issue_date, dateFormat):'';
      form.setFieldsValue(formattedValues); 
      setShowViewModal(true);
    }
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  function resetFormFields () {
    form.resetFields();
    paymentForm.resetFields();
  }

React.useEffect(() => {
  const searchTimeout = setTimeout(() => {
    setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  }, 2000);

  return () => {
    clearTimeout(searchTimeout);
  };
}, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("employee_loans/get", filterConfig);
    if (response.data) {
        setTableData(response.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Loans"
      pageButtonTitle="Add Loan"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable={isAdminLogin}
      //   withDateRangePicker
      //   onDateChange={handleDateFilter}
    >
      <Tabs 
        defaultActiveKey="pending"
        type="card"
        className="mt-4"
        items={[
          {key: "pending", label: "Pending"},
          {key: "approved", label: "Approved"},
          {key: "disapproved", label: "Disapproved"},
          {key: "", label: "All"},
        ]} 
        onChange={(e) => setFilterConfig((prev) => ({...prev, status: e}))}
      />
      <Table
        className=""
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

{/* Add & Edit Modal */}
      <Modal
        maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} Loan`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetFormFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
          initialValues={{employee_id: window.$isEmployeeLogin ? employeeId : ""}}
        >
          <Row>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="employee_id"
                    label="Employee"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select options={employeeOptions.slice(1)} searchable disabled={showEditModal}/>
                  </Form.Item>
                </Col>
              
            <Col xs={24} lg={12}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker  placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_type"
                label="Loan Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={loanTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_amount"
                label="Loan Amount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" onWheel={(e)=>e.target.blur()} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="term"
                label="Term"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={termTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="installment"
                label="Installment"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                    min: 1,
                    message: 'Installment must be greater than 0',
                  },
                ]}
              >
                <InputNumber onWheel={(e)=>e.target.blur()}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
{/* Review Modal */}
      <Modal
        title="Review Loan"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetFormFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_type"
                label="Loan Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={loanTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_amount"
                label="Loan Amount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="term"
                label="Term"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={termTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="installment"
                label="Installment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea disabled/>
              </Form.Item>
            </Col>
          </Row>
        
          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {setShowViewModal(false); form.resetFields()}}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={clickedRowData.status==="approved"||clickedRowData.status==="disapproved" || !isAdminLogin}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData, "approve")
                }}
                icon={<CheckCircleOutlined />}
                disabled={clickedRowData.status==="approved"||clickedRowData.status==="disapproved" || !isAdminLogin}
              >
                Approve
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData, "disapprove")
                }}
                icon={<CloseCircleOutlined />}
                disabled={clickedRowData.status==="approved"||clickedRowData.status==="disapproved" || !isAdminLogin}
                danger
              >
                Disapprove
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", {state: clickedRowData})
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button type='text' 
                onClick={() => {setShowViewModal(false); setShowPaymentModal(true)}} 
                icon={<MoneyCollectOutlined/>}
                disabled={clickedRowData.status!=="approved" || !isAdminLogin || clickedRowData.remaining_balance <= 0}
              >
                Add Payment
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={clickedRowData.status==="approved" || !isAdminLogin}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

{/* Payment Modal */}
    <Modal
    maskClosable={false}
        title={'Add Loan Payment'}
        centered
        open={showPaymentModal}
        footer={null}
        onCancel={() => {
          setShowPaymentModal(false)
          resetFormFields();
        }}
        // width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={paymentForm}
          name="validateOnly"
          onFinish={onSavePayment}
          initialValues={{employee_id: employeeId}}
        >
          <Row>
          <Col xs={24} lg={24}>
              <Form.Item
                name="balance"
                label="Balance"
                initialValue={formatAmount(clickedRowData.remaining_balance)}
              >
                <Input onWheel={(e)=>e.target.blur()} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="paid_amount"
                label="Paid Amount"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                    min: 1,
                    message: 'Paid amount must be greater than 0',
                  },
                ]}
              >
                <InputNumber onWheel={(e)=>e.target.blur()} min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowPaymentModal(false);
                    resetFormFields();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={paymentForm} isLoading={isLoading}/>
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}