import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row, Select, Col } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import { GetAllEmployees, GetAllRoles } from '../../utils/api/DropdownFiltersApi'
import { getEmployeeId, getIsAdminLogin } from '../../utils/Common'

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};


export default function User() {
  const [form] = Form.useForm();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId()
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    form.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin?null:employeeId,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [hasUserEmployees, setHasUserEmployees] = useState([]);
  const employeeOptions = GetAllEmployees();
  const roleOptions = GetAllRoles();
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      key: 'role_name',
    },
  ];

  const confirmDelete = (user) => {
    modal.confirm({
      title: `Are you sure you want to delete this user?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(user.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    formInputs.name = employeeName; 
    callPost(
      "users/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.user_id = clickedRowData.id;
    formInputs.name = employeeName;
    callPost(
      `users/update`, 
      formInputs,
      false
    );
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("users/delete/"+passedId,  {user_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    rowData.password = "";
    setEmployeeName(rowData.name);
    form.setFieldsValue(rowData);
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    const response = await UsePostRequest("users/get", filterConfig);
    if (response.data) {
      const employeeIds = response.data.map((user) => user.employee_id);
      setHasUserEmployees(employeeIds)
        setTableData(response.data);

    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Users"
      pageButtonTitle="Add User"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="user"
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      noButton={!isAdminLogin}
    >
      <Table
        className="mt-4"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
      maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} User`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select showSearch
                  placeholder="Select employee"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                  filterSort={(optionA, optionB) => {
                    if (optionA.label?.includes("All")) return -1;
                    if (optionB.label?.includes("All")) return 1;
                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase());
                  }}
                  options={employeeOptions?.slice(1)?.filter((emp) => !hasUserEmployees?.includes(emp.id))} 
                  onChange={(value, object) => setEmployeeName(object.label)}
                  disabled={!showAddModal}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select role"
                  options={roleOptions.slice(1)} disabled={!isAdminLogin} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={!isAdminLogin}/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review User"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select showSearch
                  options={employeeOptions} disabled/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select role"
                  options={roleOptions} disabled/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
          </Row>
 
          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {setShowViewModal(false); form.resetFields()}}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={!isAdminLogin}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
