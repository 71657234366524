import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  ArrowLeftOutlined,
  TableOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
  BarChartOutlined,
  MoneyCollectOutlined,
  ClockCircleOutlined,
  NotificationOutlined,
  MessageOutlined,
  PrinterOutlined,
  DownOutlined,
  CustomerServiceOutlined,
  CarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  theme,
} from "antd";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabContext } from "../../context/TabContext";
import Title from "antd/es/typography/Title";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { removeUserSession } from "../../utils/api/Auth";
import { refreshPage, getRoleName } from "../../utils/Common";
import "../../cssFiles/CustomStyles/Navbar.css";
import moment from "moment";
import logo from "../../assets/images/logo.png";

const { Content, Sider } = Layout;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;

const Navbar = (props) => {
  const { activeTab, setActiveTab } = useContext(TabContext);
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userRole = getRoleName();
  const dateToday = moment().format("YYYY-MM-DD");
  const currentDate = new Date();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();
  const [openKeys, setOpenKeys] = useState([]);
  const [showManage, setShowManage] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const handleMenuSelect = (key) => {
    setActiveTab(key);
  };

  async function handleLogout() {
    setIsLoading(true);
    removeUserSession();
    setTimeout(() => {
      navigate("/");
      refreshPage();
    }, 500);
    setIsLoading(false);
  }

  const confirm = () => {
    modal.confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Logout",
      cancelText: "Cancel",
      onOk: handleLogout,
    });
  };

  const adminItems = [
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: "Dashboard",
      path: "dashboard",
    },
    {
      key: 'payroll',
      icon: <MoneyCollectOutlined />,
      label: "Payroll",
      path: "payrolls",
    },
    {
      key: '13th',
      icon: <TableOutlined />,
      label: "13th Month Report",
      path: "thirteenth-month-report",
    },
    {
      key: 'timesheet',
      icon: <ClockCircleOutlined />,
      label: "Timesheet",
      path: "timesheet",
    },
    {
      key: 'memo',
      icon: <NotificationOutlined />,
      label: "Memo",
      path: "memorandums",
    },
    {
      key: 'announcement',
      icon: <NotificationOutlined />,
      label: "Announcement",
      path: "announcements",
    },
    {
      key: 'communication',
      icon: <MessageOutlined />,
      label: "Communication",
      path: "communication-letters",
    },
    {
      key: 'leave',
      icon: <CarOutlined />,
      label: "Leave",
      path: "leaves",
    },
    {
      key: 'wfa',
      icon: <UserOutlined />,
      label: "WFA",
      path: "wfas",
    },
    {
      key: 'overtime',
      icon: <ClockCircleOutlined />,
      label: "Overtime",
      path: "overtimes",
    },
    {
      key: 'loan',
      icon: <MoneyCollectOutlined />,
      label: "Loan",
      path: "loans",
    },
    {
      key: 'performance',
      icon: <BarChartOutlined />,
      label: "Performance Evaluation",
      path: "performance-evaluation",
    },
    // {
    //   key: 'birforms',
    //   icon: <FormOutlined />,
    //   label: "BIR Forms",
    //   submenu: [
    //     { key: "form1601c", label: "Form 1601-C", path: "form1601c" },
    //     { key: "form1604c", label: "Form 1604-C", path: "form1604c" },
    //     { key: "form2316", label: "Form 2316", path: "form2316" },
    //   ],
    // },
    // {
    //   key: "manualbirforms",
    //   icon: <FormOutlined />,
    //   label: "Manual BIR Forms",
    //   submenu: [
    //     {
    //       key: "manualform1601c",
    //       label: "Manual Form 1601-C",
    //       path: "manualform1601c",
    //     },
    //     {
    //       key: "manualform1604c",
    //       label: "Manual Form 1604-C",
    //       path: "manualform1604c",
    //     },
    //     {
    //       key: "manualform2316",
    //       label: "Manual Form 2316",
    //       path: "manualform2316",
    //     },
    //   ],
    // },
    {
      key: 'contribution',
      icon: <UserOutlined />,
      label: "Contribution Report",
      submenu: [
        { key: "phic", label: "PhilHealth", path: "phic-contribution-report" },
        { key: "sss", label: "SSS", path: "sss-contribution-report" },
        { key: "hdmf", label: "Pag-IBIG", path: "hdmf-contribution-report" },
      ],
    },
    {
      key: 'manageemployee',
      icon: <UserOutlined />,
      label: "Manage Employee",
      submenu: [
        { key: "employee", label: "Employee", path: "employees" },
        { key: "leave-credits", label: "Leave Credits", path: "leave-credits" },
      ],
    },
    {
      key: 'managesystem',
      icon: <SettingOutlined />,
      label: "Manage System",
      submenu: [
        {
          key: "business-unit",
          label: "Company",
          path: "business-units",
        },
        { key: "department", label: "Department", path: "departments" },
        { key: "holiday", label: "Holiday", path: "holidays" },
        { key: "letter-type", label: "Letter Type", path: "letter-types" },
        { key: "position", label: "Position", path: "positions" },
        { key: "user", label: "User", path: "users" },
      ],
    },
  ];

  const encoderItems = [
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: "Dashboard",
      path: "dashboard",
    },
    {
      key: 'timesheet',
      icon: <ClockCircleOutlined />,
      label: "Timesheet",
      path: "timesheet",
    },
    {
      key: 'memo',
      icon: <NotificationOutlined />,
      label: "Memo",
      path: "memorandums",
    },
    {
      key: 'announcement',
      icon: <NotificationOutlined />,
      label: "Announcement",
      path: "announcements",
    },
    {
      key: 'communication',
      icon: <MessageOutlined />,
      label: "Communication",
      path: "communication-letters",
    },
    {
      key: 'leave',
      icon: <CarOutlined />,
      label: "Leave",
      path: "leaves",
    },
    {
      key: 'wfa',
      icon: <UserOutlined />,
      label: "WFA",
      path: "wfas",
    },
    {
      key: 'overtime',
      icon: <ClockCircleOutlined />,
      label: "Overtime",
      path: "overtimes",
    },
    {
      key: 'loan',
      icon: <MoneyCollectOutlined />,
      label: "Loan",
      path: "loans",
    },
    {
      key: 'performance',
      icon: <BarChartOutlined />,
      label: "Performance Evaluation",
      path: "performance-evaluation",
    },
    {
      key: 'contribution',
      icon: <UserOutlined />,
      label: "Contribution Report",
      submenu: [
        { key: "phic", label: "PhilHealth", path: "phic-contribution-report" },
        { key: "sss", label: "SSS", path: "sss-contribution-report" },
        { key: "hdmf", label: "Pag-IBIG", path: "hdmf-contribution-report" },
      ],
    },
    {
      key: 'manageemployee',
      icon: <UserOutlined />,
      label: "Manage Employee",
      submenu: [
        { key: "employee", label: "Employee", path: "employees" },
        { key: "leave-credits", label: "Leave Credits", path: "leave-credits" },
      ],
    },
    {
      key: 'managesystem',
      icon: <SettingOutlined />,
      label: "Manage System",
      submenu: [
        {
          key: "business-unit",
          label: "Company",
          path: "business-units",
        },
        { key: "department", label: "Department", path: "departments" },
        { key: "holiday", label: "Holiday", path: "holidays" },
        { key: "letter-type", label: "Letter Type", path: "letter-types" },
        { key: "position", label: "Position", path: "positions" },
        { key: "user", label: "User", path: "users" },
      ],
    },
  ];

  const employeeItems = [
    {
      key: 'employeedashboard',
      icon: <HomeOutlined />,
      label: "Dashboard",
      path: "employeedashboard",
    },
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: "Profile",
      path: "employees/view",
    },
    {
      key: 'payroll',
      icon: <MoneyCollectOutlined />,
      label: "Payroll",
      path: "payrolls",
    },
    {
      key: 'leave',
      icon: <CarOutlined />,
      label: "Leave",
      path: "leaves",
    },
    {
      key: 'leave-credits',
      icon: <CarOutlined />,
      label: "Leave Credits",
      path: "leave-credits",
    },
    {
      key: 'wfa',
      icon: <UserOutlined />,
      label: "WFA",
      path: "wfas",
    },
    {
      key: 'overtime',
      icon: <ClockCircleOutlined />,
      label: "Overtime",
      path: "overtimes",
    },
    {
      key: 'loan',
      icon: <MoneyCollectOutlined />,
      label: "Loan",
      path: "loans",
    },
  ];

  React.useEffect(() => {
    if (userRole) {
      if (userRole === "admin") {
        setMenuItems(adminItems)
      } else if (userRole === "hr") {
        setMenuItems(encoderItems)
      } else {
        setMenuItems(employeeItems)
      }
    }
  }, [userRole])

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="menu-cont"
      >
        <div className="demo-logo-vertical" />
        <div className="white-bg">
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: "auto",
          }}
        />
        <div className="d-flex justify-content-center my-2">
          <img src={logo} alt="" className="navbar-logo" />
        </div>
        </div>
        <Menu
          // theme="dark"
          mode="inline"
          selectedKeys={[activeTab]}
          onClick={({ key }) => handleMenuSelect(key)}
          role="button"
          openKeys={openKeys}
          onOpenChange={(keys) => setOpenKeys(keys)}
          className="navbar-menu-container"
        >
          {menuItems.map((item) =>
            item.submenu ? (
              <SubMenu
                key={item.key}
                title={
                  <>
                    {item.icon}
                    <span>{item.label}</span>
                  </>
                }
                onTitleClick={() => setShowManage(!showManage)} // Use item.key as the identifier for the submenu
                open={showManage === true} // Control the submenu's open state
              >
                {item.submenu.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    <div
                      onClick={() =>
                        navigate(`/${subItem.path}`)
                      }
                      role="button"
                    >
                      {subItem.label}
                    </div>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key}>
                <div
                  onClick={() =>
                    navigate(`/${item.path}`)
                  }
                  role="button"
                >
                  {item.icon}
                  <span>{item.label}</span>
                </div>
              </Menu.Item>
            )
          )}

          {
            !window.$isEmployeeLogin && <Menu.Item
            key="support"
            disabled={isLoading}
            className="no-underline"
          >
            <a href="mailto:support@anantasolutioncorp.com" className="">
              <CustomerServiceOutlined className="" />
              <span> Support</span>{" "}
            </a>
          </Menu.Item>
          }

          <Menu.Item
            key="logout"
            onClick={confirm}
            disabled={isLoading}
            className="mb-5"
          >
            <LogoutOutlined />
            <span>Logout</span>
          </Menu.Item>
          {contextHolder}
        </Menu>
      </Sider>

      <Layout
        className={
          props.allowOverflow ? "main-content allow-overflow" : "main-content"
        }
      >
        <Content
          style={{
            flex: 1,
            background: colorBgContainer,
          }}
        >
          {props.isManager && (
            <Row
              style={{ alignItems: "center" }}
              gutter={[16, 16]}
              justify="space-between"
            >
              <Col lg={7} xs={24}>
                <Title level={3}>{props?.pageTitle || ""}</Title>
              </Col>

              <Col lg={17} xs={24} className="d-flex justify-content-end">
                <Space className="d-flex justify-content-start" wrap>
                  {props.withDateRangePicker && (
                    <RangePicker
                      style={{ width: 300 }}
                      onChange={props.onDateChange}
                      placeholder={[dateToday, dateToday]}
                    />
                  )}

                  {props.withYearFilter && (
                    <DatePicker
                      style={{ width: 150 }}
                      onChange={props.onYearChange}
                      picker="year"
                      placeholder={currentDate.getFullYear()}
                    />
                  )}

                  {props.searchable && (
                    <Input
                      style={{ width: 250 }}
                      suffix={
                        <FontAwesomeIcon icon={icon({ name: "search" })} />
                      }
                      placeholder={"Search " + (props.searchPlaceholder || "")}
                      onChange={(e) => {
                        props?.setSearchQuery(e.target.value)
                      }}
                      onKeyDown={props?.onSearch}
                      allowClear
                    />
                  )}

                  {props.withPrintBtn && (
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary" className="w-100">
                          <PrinterOutlined /> Print
                        </Button>
                      )}
                      content={() => props.printContent.current}
                    />
                  )}

                  {props.isDownloadBtn && (
                    <Button
                      type="primary"
                      onClick={props?.onClickButton}
                      icon={<FontAwesomeIcon icon={faDownload} />}
                      className="w-100"
                    >
                      {props?.pageButtonTitle || "Add"}
                    </Button>
                  )}

                  {props.noButton ? (
                    ""
                  ) : (
                    <Button
                      type="primary"
                      onClick={props?.onClickButton}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                    >
                      {props?.pageButtonTitle || "Add"}
                    </Button>
                  )}

                  {props.printButtonTitle && (
                    <Button
                      type="primary"
                      onClick={props?.onClickButton}
                      className="w-100"
                    >
                      <PrinterOutlined /> {props.printButtonTitle}
                    </Button>
                  )}

                  {props.actionDropdownButton && (
                    <Dropdown
                      overlay={
                        <Menu>
                          {props.actionDropdownOptions.map((opt) => (
                            <Menu.Item key={opt.value} onClick={opt.action}>
                              {opt.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        onClick={(e) => e.preventDefault()}
                      >
                        Select Action <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                </Space>
              </Col>
            </Row>
          )}

          {props?.isForm ? (
            <>
              {!props?.noBackButton && (
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              )}
              <Content className="form-content">
                <Space className="d-flex justify-content-between">
                  {props?.isForm && (
                    <Title level={2}>{props?.formTitle || ""}</Title>
                  )}
                  {props.withPrintBtn && (
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary">
                          <PrinterOutlined /> {props.printText}
                        </Button>
                      )}
                      content={() => props.printContent.current}
                    />
                  )}
                </Space>
                {props.children}
              </Content>
            </>
          ) : (
            <>{props.children}</>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
Navbar.propTypes = {
  allowOverflow: PropTypes.bool,
  isManager: PropTypes.bool,
  pageTitle: PropTypes.string,
  withDateRangePicker: PropTypes.bool,
  onDateChange: PropTypes.func,
  withYearFilter: PropTypes.bool,
  onYearChange: PropTypes.func,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setSearchQuery: PropTypes.func,
  onSearch: PropTypes.func,
  withPrintBtn: PropTypes.bool,
  printContent: PropTypes.node,
  isDownloadBtn: PropTypes.bool,
  onClickButton: PropTypes.func,
  pageButtonTitle: PropTypes.string,
  printButtonTitle: PropTypes.string,
  actionDropdownButton: PropTypes.array,
  isForm: PropTypes.bool,
  noBackButton: PropTypes.bool,
  formTitle: PropTypes.string,
  printText: PropTypes.string,
  children: PropTypes.node,
};
export default Navbar;
