import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row, Col, DatePicker, Select } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment'
import dayjs from 'dayjs'
import { removeUnderscoreToSentence } from '../../utils/Common'


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};


export default function Holiday() {
  const [form] = Form.useForm();
  const dateToday = new Date();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    form.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
//   const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    year: dateToday.getFullYear(),
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  
  const tableColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date, record) => (
            <div>{moment(date).format('MMM DD, YYYY')}</div>
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Add-on Rate (%)',
        dataIndex: 'bonus',
        key: 'bonus',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type) => (
          <div>{removeUnderscoreToSentence(type)}</div>
        )
    },
  ];

  const confirmDelete = (holiday) => {
    modal.confirm({
      title: `Are you sure you want to delete this holiday?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(holiday.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    formInputs.date = formInputs.date?.format('YYYY-MM-DD');
    formInputs.remarks = formInputs.type;
    callPost(
      "holidays/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.holiday_id = clickedRowData.id;
    formInputs.date = formInputs.date?.format('YYYY-MM-DD');
    formInputs.remarks = formInputs.type;
    callPost(
      `holidays/update/${clickedRowData.id}`, 
      formInputs,
      false
    );
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("holidays/delete/"+passedId,  {holiday_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const initialValues = {...rowData};
    initialValues.date = dayjs(rowData.date, 'YYYY-MM-DD');
    form.setFieldsValue(initialValues); 
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });


React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("holidays/get", filterConfig);
    if (response.data) {
        setTableData(response.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Holidays"
      pageButtonTitle="Add Holiday"
      setSearchQuery={(e)=>setSearchTerm(e)} onSearch={handleSearch} searchPlaceholder="holiday" 
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      withYearFilter
      onYearChange={(e) => {
        if(e) {
          setFilterConfig((prev)=>({...prev, year: e.$y}))
        } else {
          setFilterConfig((prev)=>({...prev, year: null}))
        }}}
    >
      <Table
        className="mt-4"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
      maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} Holiday`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
        size="md"
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true, whitespace: true
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    if (e === "special_non_working") {
                      form.setFieldsValue({bonus: 130})
                    } else if (e==="regular") {
                      form.setFieldsValue({bonus: 200})
                    }
                  }}
                  options={[
                    { value: "regular", label: "Regular" },
                    {
                      value: "special_non_working",
                      label: "Special Non-Working",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="bonus" label="Add-on Rate (%)" initialValue={0}>
                <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                    form.resetFields();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Holiday"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
        size="md"
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
        >
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label="Name"
              
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="date"
                label="Date"
            
              >
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="type"
                label="Type"
              
              >
                <Select
                  onChange={(e) => {
                    if (e === "special_non_working") {
                      form.setFieldsValue({bonus: 130})
                    } else if (e==="regular") {
                      form.setFieldsValue({bonus: 200})
                    }
                  }}
                  options={[
                    { value: "regular", label: "Regular" },
                    {
                      value: "special_non_working",
                      label: "Special Non-Working",
                    },
                  ]}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="bonus" label="Add-on Rate (%)" initialValue={0}>
                <Input type="number" onWheel={(e)=>e.target.blur()} disabled/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {
                  form.resetFields();
                  setShowViewModal(false)
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}