import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { globalStyles } from './utils/theme';
import { getRoleName } from './utils/Common';

//GLOBAL VARIABLES
window.$company_name = "RoyalCare Marketing Services Inc.";
window.$company_address = " Unit 1502 139 Corporate Center, Valero St., Salcedo Village Makati";
window.$company_ceo = "---";
window.$company_hr = "---";
window.$company_philhealth_no = "00100004482-3";
window.$company_tin = "009-223-513-000";
window.$company_sss_no = "03-9519322-4";
window.$company_employer_type = "Private";

window.$isAdminLogin = getRoleName()==="admin";
window.$isEmployeeLogin = getRoleName()==="employee";
window.$isEncoderLogin = getRoleName()==="hr";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:   globalStyles.colorPrimary,
          colorSuccess:   globalStyles.colorSuccess,
          colorWarning:   globalStyles.colorWarning,
          colorTextBase:  globalStyles.colorTextBase,

          fontFamily  :   globalStyles.fontRegular
      }}}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
