import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
  Table,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Form,
  DatePicker,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import { GetAllEmployees } from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import ReactQuillEditor from "../../components/ReactQuillEditor/ReactQuillEditor";
import { getIsAdminLogin, getEmployeeId } from "../../utils/Common";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

export default function WorkFromAnywhere() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const dateToday = new Date();
  const [content, setContent] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const employeeOptions = GetAllEmployees();
  const dateFormat = "YYYY-MM-DD";
  const [searchTerm, setSearchTerm] = useState(null);

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    // employee_id: isAdminLogin ? "" : employeeId,
    year: dateToday.getFullYear(),
    status: "pending",
  });

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Date Applied",
      dataIndex: "added_on",
      key: "added_on",
      render: (added_on, record) => (
        <div>{moment(added_on).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (data, record) => (
        <div>{moment(data).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const onFinish = async (formInputs) => {
    formInputs.date = formInputs.date.format(dateFormat);
    formInputs.status = "pending";
    formInputs.admin_remarks = "";

    formInputs.file_names = [];
    callPost("wfa_requests/add", formInputs, false);
  };

  const onEdit = async (formInputs) => {
    formInputs.date = formInputs.date.format(dateFormat);
    formInputs.admin_remarks = "";
    formInputs.status = clickedRowData.status;
    formInputs.file_names = [];

    callPost(`wfa_requests/update/` + clickedRowData.id, formInputs, false);
  };

  const confirmApprove = (row, status) => {
    const newStatus = `${status}d`;
    modal.confirm({
      title: `Are you sure you want to ${status} this WFA?`,
      icon: <CheckCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApprove(row.id, newStatus),
    });
  };

  function handleApprove(passedId, newStatus) {
    callPost(
      "wfa_requests/approve",
      { employee_wfa_id: passedId, status: newStatus },
      false
    );
    resetValues();
  }

  const confirmDelete = (leave) => {
    modal.confirm({
      title: `Are you sure you want to delete this WFA?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(leave.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("wfa_requests/delete/" + passedId, {}, false);
    resetValues();
  }

  function resetValues() {
    form.resetFields();
    setContent("");
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  const handleRowClick = (rowData) => {
    if (window.$isEmployeeLogin) {
      navigate("view", { state: rowData });
    } else {
      setContent(rowData.remarks);
      setClickedRowData(rowData);
      const formattedValues = { ...rowData };
      formattedValues.date = rowData.date
        ? dayjs(rowData.date, dateFormat)
        : "";
      form.setFieldsValue(formattedValues);
      setShowViewModal(true);
    }
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const handleQuillChange = (html) => {
    setContent(html);
    form.setFieldValue("remarks", html);
  };

  const handleDateFilter = (e) => {
    if (e) {
      setFilterConfig((prev) => ({
        ...prev,
        date_from: e[0].format(dateFormat),
        date_to: e[1].format(dateFormat),
      }));
    } else {
      setFilterConfig((prev) => ({
        ...prev,
        date_from: "",
        date_to: "",
      }));
    }
  };

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest("wfa_requests/get", filterConfig);
      if (response.data) {
        setTableData(response.data);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="WFAs"
      pageButtonTitle="Add WFA"
      onClickButton={() => {
        setShowAddModal(true);
        setShowViewModal(false);
      }}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      isManager
      searchable={isAdminLogin}
      withDateRangePicker
      onDateChange={handleDateFilter}
    >
      <Tabs
        defaultActiveKey="pending"
        type="card"
        className="mt-4"
        items={[
          { key: "pending", label: "Pending" },
          { key: "approved", label: "Approved" },
          { key: "disapproved", label: "Disapproved" },
          { key: "", label: "All" },
        ]}
        onChange={(e) => setFilterConfig((prev) => ({ ...prev, status: e }))}
      />
      <Table
        className=""
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        maskClosable={false}
        title={`${showAddModal ? "Add" : "Edit"} WFA`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
          initialValues={{
            employee_id: window.$isEmployeeLogin ? employeeId : "",
          }}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={employeeOptions.slice(1)}
                  showSearch
                  filterOption={(input, option) =>
                    option.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item name="remarks" label="Remarks" initialValue="">
                <ReactQuillEditor
                  content={content}
                  handleQuillChange={handleQuillChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review WFA"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={employeeOptions.slice(1)}
                  searchable
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item name="remarks" label="Remarks" initialValue="">
                <ReactQuillEditor
                  content={content}
                  handleQuillChange={handleQuillChange}
                  isDisabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {
                  setShowViewModal(false);
                  resetValues();
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={
                  clickedRowData.status === "approved" ||
                  clickedRowData.status === "disapproved"
                }
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData, "approve");
                }}
                icon={<CheckCircleOutlined />}
                disabled={
                  clickedRowData.status === "approved" ||
                  clickedRowData.status === "disapproved" ||
                  !isAdminLogin
                }
              >
                Approve
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData, "disapprove");
                }}
                icon={<CloseCircleOutlined />}
                disabled={clickedRowData.status === "disapproved"}
                style={{ color: "red" }}
              >
                Disapprove
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", { state: clickedRowData });
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={clickedRowData.status === "approved"}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
