import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import PropTypes from "prop-types";

export default function ReactQuillEditor (props) {
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
          ],
    }
    const formats = [
      'font','size',
      'bold','italic','underline','strike',
      'color','background',
      'script',
      'header','blockquote','code-block',
      'indent','list',
      'direction','align',
      'link','image','video','formula',
    ]

    return (
        <div>
            <ReactQuill value={props.content} onChange={props.handleQuillChange} readOnly={props.isDisabled} formats={formats} modules={modules}/>
        </div>
    )
}

ReactQuillEditor.propTypes = {
    content: PropTypes.string,
    handleQuillChange: PropTypes.func,
    isDisabled: PropTypes.bool,
}