import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Table} from 'antd'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment';
import { getEmployeeId, getIsAdminLogin } from '../../utils/Common';

export default function LeaveCredit () {
  const isAdminLogin = getIsAdminLogin();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    date: moment().format("YYYY-MM-DD"),
    employee_id: window.$isEmployeeLogin ? getEmployeeId() : null,
    year: moment().format("YYYY"),
  });

//   Dynamic table columns because leave types are dynamic as well
  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Sick Leave Balance",
      dataIndex: "sick_leave_balance",
      key: "sick_leave_balance",
    },
    {
      title: "Vacation Leave Balance",
      dataIndex: "vacation_leave_balance",
      key: "vacation_leave_balance",
    }
  ]

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("employee_leaves/get_leave_credit ", filterConfig);
    if (response.response) {
      if (window.$isEmployeeLogin) {
        const tempData = []
        tempData.push(response.response.leave_credits)
        setTableData(tempData)
      } else {
        setTableData(response.response.leave_credits)
      }
    }
    setIsFetching(false);
  }

React.useEffect(() => {
    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Employee Leave Credits" noButton onSearch={handleSearch} setSearchQuery={(e) => setSearchTerm(e)} isManager searchable={isAdminLogin} withYearFilter
    onYearChange={(e) => {
      if (e) {
        setFilterConfig((prev) => ({ ...prev, year: e.$y }));
      } else {
        setFilterConfig((prev) => ({ ...prev, year: null }));
      }
    }}>
        <Table className='mt-4' rowKey="id"
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching} />
    </Navbar>
  )
}