import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Space,
  Carousel,
  Row,
  Col,
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import "../../cssFiles/CustomStyles/Dashboard.css";
import { formatDate, getLocalData } from "../../utils/Common";
import { UseGetRequest } from "../../utils/api/ApiRequests";
import { getEmployeeId } from "../../utils/Common";

export default function EmployeeDashboard() {
  let navigate = useNavigate();
  const employeeId = getEmployeeId();
  const [date, setDate] = useState(new Date());
  const [user, setUser] = useState("");

  const [announcements, setAnnouncements] = useState([]);
  const [memos, setMemos] = useState([]);
  const [letters, setLetters] = useState([]);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    async function getName() {
      const res = await getLocalData("name");
      setUser(res);
    }
    getName();
  });

  React.useEffect(() => {

    async function fetchData() {
      setAnnouncements([]);
      setMemos([]);
      setLetters([]);
      const announecements = await UseGetRequest("announcements/get", {employee_id: employeeId});
      if (announecements.data) {
        setAnnouncements(announecements.data.data.announcements);
      }

      const memos = await UseGetRequest("memos/get", {employee_id: employeeId});
      if (memos.data) {
        setMemos(memos.data.data.memos);
      }

      const letters = await UseGetRequest("letters/get", {employee_id: employeeId});
      if (letters.data) {
        setLetters(letters.data.data.letters)
      }
    }

    fetchData();

  }, []);


  return (
    <Navbar>
      <Space className="d-flex justify-content-between p-3" wrap>
        <Title level={3}>Hello, {user}! 👋🏻</Title>
        <Title
          level={4}
          className=""
        >
          <CalendarOutlined className="me-2" /> {formattedDate}, {formattedTime}
        </Title>
      </Space>

      <Row className="p-3">
        <Col className="p-1" xs={24} lg={12}>
          <Card
            className="shadow carousel-card"
            style={{ height: "50vh", paddingRight: 0, overflowY: "scroll" }}
          >
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Announcements</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/announcements")}>
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Carousel autoplay>
              {announcements.map((data, index) => {
                return (
                  <div key={index} className="px-3">
                    <Row className="d-flex justify-content-between carousel-header">
                      <Col className="welcome-msg">
                        {data.subject ? data.subject : ""}
                      </Col>
                      <Col className="text-end">
                        {data.announcement_date
                          ? formatDate(data.announcement_date)
                          : ""}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.content }}
                        className="p-3 carousel-content"
                      />
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={12}>
          <Card
            className="shadow carousel-card"
            style={{ height: "50vh", paddingRight: 0, overflowY: "scroll" }}
          >
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Memorandum</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/memorandums")}>
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Carousel autoplay>
              {memos.map((data, index) => {
                return (
                  <div key={index} className="px-3">
                    <Row className="d-flex justify-content-between carousel-header">
                      <Col className="welcome-msg">
                        {data.subject ? data.subject : ""}
                      </Col>
                      <Col className="text-end">
                        {data.announcement_date
                          ? formatDate(data.announcement_date)
                          : ""}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.content }}
                        className="p-3 carousel-content"
                      />
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={12}>
          <Card
            className="shadow carousel-card"
            style={{ height: "50vh", paddingRight: 0, overflowY: "scroll" }}
          >
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Communication Letters</Title>
              </Col>
            </Row>
            <Divider />
            <Carousel autoplay>
              {letters?.map((data, index) => {
                return (
                  <div key={index} className="px-3">
                    <Row className="d-flex justify-content-between ">
                      <Col className="welcome-ms">
                        From: <span className="carousel-header ms-4">{data.letter_from}</span>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between ">
                      <Col className="welcome-msg">
                        Subject: <span className="carousel-header ms-2">{data.subject}</span>
                      </Col>
                      <Col className="text-end carousel-header">
                        {data.letter_date
                          ? formatDate(data.letter_date)
                          : ""}
                      </Col>
                    </Row>
                    
                    <Row>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.content }}
                        className="p-3 carousel-content"
                      />
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        </Col>
      </Row>

    </Navbar>
  );
}
