import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Title from "antd/es/typography/Title";
import {
  Space,
  Row,
  Badge, Calendar
} from "antd";
import moment from "moment";
import { UseGetRequest, UsePostRequest } from "../../utils/api/ApiRequests";
import { getIsAdminLogin, getEmployeeId } from "../../utils/Common";
import '../../cssFiles/CustomStyles/EventCalendar.css';

// const {RangePicker} = DatePicker;
export default function EventsCalendar() {
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [date, setDate] = useState(new Date());
  const [filterConfig, setFilterConfig] = useState({
    employee_id: null,
    status: "approved",
  });

  const [holidays, setHolidays] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [wfas, setWfas] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [memos, setMemos] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const getListData = (value, period) => {
    const calendarDate = period==="date"?value.format('YYYY-MM-DD'):value.format('YYYY-MM');
    const calendarDateOnly = period==="date"?value.format('MM-DD'):value.format('MM');

    const calendarDateFormat = period==="date" ? 'YYYY-MM-DD' : 'YYYY-MM';
    const calendarDateOnlyFormat = period==="date" ? 'MM-DD' : 'MM';

    const holidayEvents = holidays.filter(data => moment(data.date).format(calendarDateFormat) === calendarDate);
    const tempLeaves = leaves.filter(data => moment(data.date).format(calendarDateFormat) === calendarDate);
    const tempWfas = wfas.filter(data => moment(data.date).format(calendarDateFormat) === calendarDate);
    const tempAnnouncements = announcements.filter(data => moment(data.announcement_date).format(calendarDateFormat) === calendarDate);
    const tempMemos = memos.filter(data => moment(data.memo_date).format(calendarDateFormat) === calendarDate);
    const tempBirthdays = birthdays.filter(data => moment(data.birthdate).format(calendarDateOnlyFormat) === calendarDateOnly);
    const tempAnniv = anniversaries.filter(data => moment(data.date_hired).format(calendarDateOnlyFormat) === calendarDateOnly);

    let listData = []
    holidayEvents.forEach((event) => {
      var info = {}
      info.type = 'holiday';
      info.content = `🎄 ${event.name}`;
      info.className = "holiday-badge";
      listData.push(info);
    })

    tempAnnouncements.forEach((event) => {
      var info = {}
      info.type = 'announcement';
      info.content = `📢 ${event.subject}`;
      info.className = "announcement-badge";
      listData.push(info);
    })

    tempMemos.forEach((event) => {
      var info = {}
      info.type = 'memo';
      info.content = `📝 ${event.subject}`;
      info.className = "memo-badge";
      listData.push(info);
    })

    tempBirthdays.forEach((event) => {
      var info = {}
      info.type = 'birthday';
      info.content = `🎂 ${event.name}`;
      info.className = "birthday-badge";
      listData.push(info);
    })

    tempAnniv.forEach((event) => {
      var info = {}
      info.type = 'anniversary';
      info.content = `💼 ${event.name}`;
      info.className = "anniversary-badge";
      listData.push(info);
    })

    tempLeaves.forEach((event) => {
      var info = {}
      info.type = 'leave';
      info.content = `🚘 ${event.last_name} - ${event.leave_type}`;
      info.className = "leave-badge";
      listData.push(info);
    })

    tempWfas.forEach((event) => {
      var info = {}
      info.type = 'wfa';
      info.content = `🏠 ${event.last_name}`;
      info.className = "wfa-badge";
      listData.push(info);
    })

    return listData;
  };

  // const getMonthData = (value) => {
  //   if (value.month() === 8) {
  //     return 1394;
  //   }
  // };

    const monthCellRender = (value) => {
      const listData = getListData(value, "month");
      return (
        <Row className="events">
          {listData && listData.map((item) => (
              <Badge status={item.type} text={item.content} className={item.className}/>
          ))}
        </Row>
      )
    };

    const dateCellRender = (value) => {
      const listData = getListData(value, "date");
      return (
        <Row className="events">
          {listData && listData.map((item) => (
              <Badge status={item.type} text={item.content} className={item.className}/>
          ))}
        </Row>
      );
    };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };


  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    async function fetchAllData() {
      setHolidays([])
      setLeaves([])
      setWfas([])
      setAnnouncements([])
      setMemos([]);
      setBirthdays([]);
      setAnniversaries([]);

      const tempHolidays = await UsePostRequest("holidays/get", filterConfig);
      if (tempHolidays.data) {
          setHolidays(tempHolidays.data);
      }

      const tempLeaves = await UsePostRequest("employee_leaves/get", filterConfig);
      if (tempLeaves.data) {
        var tempArray = []
        tempLeaves.data.forEach((row) => {
          var startDate = new Date(row.date_from);
          var endDate = new Date(row.date_to);

          for (let date=startDate; date<=endDate; date.setDate(date.getDate()+1)) {
            var info = {}
            info.last_name = row.employee_name.split(',')[0];
            info.date = moment(date).format('YYYY-MM-DD');
            info.leave_type = row.leave_type;
            tempArray.push(info);
          }
        })
        setLeaves(tempArray);
      }

      const tempWfas = await UsePostRequest("wfa_requests/get", filterConfig);
      if (tempWfas.data) {
        var tempWfaArray = []
        tempWfas.data.forEach((row) => {
          var startDate = new Date(row.date_from);
          var endDate = new Date(row.date_to);

          for (let date=startDate; date<=endDate; date.setDate(date.getDate()+1)) {
            var info = {}
            info.last_name = row.employee_name.split(',')[0];
            info.date = moment(date).format('YYYY-MM-DD');
            tempWfaArray.push(info);
          }
        })
        setWfas(tempWfaArray);
      }

      const tempAnnouncements = await UseGetRequest("announcements/get", filterConfig);
      if (tempAnnouncements.data) {
        setAnnouncements(tempAnnouncements.data.data.announcements);
      }

      const tempMemos = await UseGetRequest("memos/get", filterConfig);
      if (tempMemos.data) {
        setMemos(tempMemos.data.data.memos);
      }

      const employeeData = await UsePostRequest("employees/get_all", {});
      if (employeeData.data) {
        const temp = employeeData.data.map((row) => {
          var info = {}
          info.name = `${row.first_name}${row.middle_name?` ${row.middle_name}`:''} ${row.last_name}`;
          info.birthdate = row.birthdate;
          info.date_hired = row.date_hired;
          return info;
        })
        setBirthdays(temp);
        setAnniversaries(temp);
      }
    }

    fetchAllData();
  }, []);


  return (
    <Navbar>
      <Space className="d-flex justify-content-between p-3" wrap>
        <Title level={4}>
          {formattedDate}, {formattedTime}
        </Title>
      </Space>

      <Row>
        <Calendar cellRender={cellRender} />
      </Row>
    </Navbar>
  );
}
