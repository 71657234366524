import React, {useState} from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Button } from "antd";
import { ArrowLeftOutlined } from "@mui/icons-material";
import { refreshPage } from "../../../utils/Common";
import toast from "react-hot-toast";
import "../FaceRecognition.css";


export default function FaceAttendance () {
    const navigate = useNavigate();
    const location = useLocation();
    const [inProgress, setInProgress] = useState(false);
    const purpose = location.state;


    function handleError(errCode){
        // Handle error here
        // Log all possible error codes during user interaction..
        // Refer to: https://faceio.net/integration-guide#error-codes
        // for a detailed overview when these errors are triggered.
        const errorCode = window.$faceioInstance.fetchAllErrorCodes();
        switch (errCode) {
            case errorCode.PERMISSION_REFUSED:
                toast.error("Access to the Camera stream was denied by the end user");
                break;
            case errorCode.NO_FACES_DETECTED:
                toast.error("No faces were detected during the enroll or authentication process");
                break;
            case errorCode.UNRECOGNIZED_FACE:
                toast.error("Unrecognized face on this application's Facial Index");
                break;
            case errorCode.MANY_FACES:
                toast.error("Two or more faces were detected during the scan process");
                break;
            case errorCode.FACE_DUPLICATION:
                toast.error("User enrolled previously (facial features already recorded). Cannot enroll again!");
                break;
            case errorCode.MINORS_NOT_ALLOWED:
                toast.error("Minors are not allowed to enroll on this application!");
            break;
            case errorCode.PAD_ATTACK:
                toast.error("Presentation (Spoof) Attack (PAD) detected during the scan process");
                break;
            case errorCode.FACE_MISMATCH:
                toast.error("Calculated Facial Vectors of the user being enrolled do not matches");
                break;
            case errorCode.WRONG_PIN_CODE:
                toast.error("Wrong PIN code supplied by the user being authenticated");
                break;
            case errorCode.PROCESSING_ERR:
                toast.error("Server side error");
                break;
            case errorCode.UNAUTHORIZED:
                toast.error("Your application is not allowed to perform the requested operation (eg. Invalid ID, Blocked, Paused, etc.). Refer to the FACEIO Console for additional information");
                break;
            case errorCode.TERMS_NOT_ACCEPTED:
                toast.error("Terms & Conditions set out by FACEIO/host application rejected by the end user");
                break;
            case errorCode.UI_NOT_READY:
                toast.error("The FACEIO Widget could not be (or is being) injected onto the client DOM");
                break;
            case errorCode.SESSION_EXPIRED:
                toast.error("Client session expired. The first promise was already fulfilled but the host application failed to act accordingly");
                break;
            case errorCode.TIMEOUT:
                toast.error("Ongoing operation timed out (eg, Camera access permission, ToS accept delay, Face not yet detected, Server Reply, etc.)");
                break;
            case errorCode.TOO_MANY_REQUESTS:
                toast.error("Widget instantiation requests exceeded for freemium applications. Does not apply for upgraded applications");
                break;
            case errorCode.EMPTY_ORIGIN:
                toast.error("Origin or Referer HTTP request header is empty or missing");
                break;
            case errorCode.FORBIDDDEN_ORIGIN:
                toast.error("Domain origin is forbidden from instantiating fio.js");
                break;
            case errorCode.FORBIDDDEN_COUNTRY:
                toast.error("Country ISO-3166-1 Code is forbidden from instantiating fio.js");
                break;
            case errorCode.SESSION_IN_PROGRESS:
                toast.error("Another authentication or enrollment session is in progress");
                setTimeout(() => refreshPage(), 1000);
                break;
            // case errorCode.NETWORK_IO:
            default:
                toast.error("Error while establishing network connection with the target FACEIO processing node");
                break;
        }
    }

    async function authenticateUserTimeIn () {
        setInProgress(true);
        try {
            const userData = await window.$faceioInstance.authenticate({
            "locale": "auto",
            "permissionTimeout": 5,
                "termsTimeout": 5,
                "replyTimeout": 5,
            });
            if (userData) {
            }
        } catch (error) {
            handleError(error);
            if (error !== 20) {
                setTimeout(() => {navigate("/"); refreshPage()}, 2000);
            }
        }
        setInProgress(false);
    }

    async function authenticateUserTimeOut () {
        setInProgress(true);
        try {
            const userData = await window.$faceioInstance.authenticate({
            "locale": "auto",
            "permissionTimeout": 5,
                "termsTimeout": 5,
                "replyTimeout": 5,
            });
            if (userData) {
            // window.$faceioInstance.restartSession();
            }
        } catch (error) {
            handleError(error);
            if (error !== 20) {
                setTimeout(() => {navigate("/"); refreshPage()}, 1000);
            }
        }
        setInProgress(false);
    }

    function handleSubmit () {
        if (purpose === "TIME-IN") {
            authenticateUserTimeIn();
        } else if (purpose === "TIME-OUT") {
            authenticateUserTimeOut();
        }
    }

    return (
        // <Navbar isForm>
            <Container fluid>
                <Button type="text" className="mt-3" icon={<ArrowLeftOutlined />} onClick={() => navigate("/")} disabled={inProgress}>Back</Button>
                <div id="faceio-modal"></div>
                <Row className="instruction-container" onClick={handleSubmit}>FACE THE CAMERA TO CAPTURE</Row>
            </Container>
        // </Navbar>
    )
}