export const globalStyles = {
    /* brand colors */
    colorPrimary: "#458381",
    colorSuccess: "#9de038",
    colorWarning: "#fa9714",
    colorTextBase: "#2f2f2f",

    /* fonts */
    fontLight: "var(--primary-font-light)",
    fontMedium: "var(--primary-font-medium)",
    fontRegular: "var(--primary-font-regular)",
    fontBold: "var(--primary-font-bold)",
    fontSemibold: "var(--primary-font-semibold)",
}