import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UseGetRequest } from '../../utils/api/ApiRequests'


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};

const EditSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Save Changes</Button>
    );
};



export default function LetterType () {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
  ];

  const confirmDelete = (letterType) => {
    modal.confirm({
      title: `Are you sure you want to delete this letter type?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(letterType.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost(
      "letter_types/add", 
      formInputs,
      false
    );
    form.resetFields();
  };

  const onEdit = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.letter_type_id = clickedRowData.id;
    callPost(
      `letter_types/update`, 
      formInputs,
      false
    );
    editForm.resetFields();
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("letter_types/delete/"+passedId,  {letter_type_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    editForm.setFieldsValue(rowData);
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

//   useEffect(() => {
//     editForm.setFieldsValue(initialValues);        
// }, [initialValues]);

React.useEffect(() => {
  const searchTimeout = setTimeout(() => {
    setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  }, 2000);

  return () => {
    clearTimeout(searchTimeout);
  };
}, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UseGetRequest("letter_types/get", filterConfig);
    if (response.data) {
        setTableData(response.data.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Letter Types" pageButtonTitle="Add Letter Type" setSearchQuery={(e)=>setSearchTerm(e)} onSearch={handleSearch} searchPlaceholder="letter type" onClickButton={()=>setShowAddModal(true)} isManager searchable>
        <Table className='mt-4' rowKey="id"
          onRow={(record) => ({
            ...rowProps(record), style: { cursor: 'pointer' },
          })} 
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching || isLoading} />

        <Modal
          maskClosable={false}
          title="Add Letter Type"
          centered
          open={showAddModal}
          footer={null}
          onCancel={() => {
            setShowAddModal(false); 
            form.resetFields();
          }}
        >
          <Form className='mt-3 mx-3' form={form} name="validateOnly" layout='vertical' onFinish={onFinish}>
            <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true, whitespace: true
                  },
                ]}
              >
              <Input />
            </Form.Item>

            <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
              <Input />
            </Form.Item>

            <Form.Item>
              <Row className='d-flex justify-content-end'>
                <Row>
                  <Button className='me-2' danger onClick={()=>setShowAddModal(false)} disabled={isLoading}>Cancel</Button>
                  <AddSubmitButton form={form} isLoading={isLoading} />
                </Row>
              </Row>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
        maskClosable={false}
          title="Edit Letter Type"
          centered
          open={showEditModal}
          footer={null}
          onCancel={() => {
            setShowEditModal(false); 
            editForm.resetFields();
          }}
        >
          <Form className='mt-3 mx-3' form={editForm} name="editForm" layout='vertical' onFinish={onEdit}>
          <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true, whitespace: true
                  },
                ]}
              >
              <Input />
            </Form.Item>

          <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
              <Input />
            </Form.Item>

            <Form.Item>
              <Row className='d-flex justify-content-end'>
                  <Button danger onClick={()=>setShowEditModal(false)} disabled={isLoading}>Cancel</Button>
                  <EditSubmitButton form={editForm} isLoading={isLoading} />
              </Row>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Review Letter Type"
          centered
          open={showViewModal}
          footer={null}
          onCancel={() => {
            setShowViewModal(false); 
            editForm.resetFields();
          }}
        >
          <Form className='mt-3 mx-3' form={editForm} name="editForm" layout='vertical'>
            
          <Form.Item
                name="name"
                label="Name"
             
              >
              <Input disabled/>
            </Form.Item>

          <Form.Item
                name="description"
                label="Description"
            
              >
              <Input disabled/>
            </Form.Item>
          
            <Form.Item>
              <Row className='d-flex justify-content-end'>
                  <Button danger onClick={()=>setShowViewModal(false)} disabled={isLoading}>Cancel</Button>
                  <Button type="text" onClick={()=>{setShowViewModal(false); setShowEditModal(true)}} icon={<EditOutlined/>}>Edit</Button>
                  <Button type="text" danger onClick={()=>{confirmDelete(clickedRowData)}} icon={<DeleteOutlined/>}>Delete</Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
        {contextHolder}
    </Navbar>
  )
}
