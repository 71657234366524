import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import "../../cssFiles/CustomStyles/Login.css";
import Title from 'antd/es/typography/Title';
import { login } from '../../utils/api/Auth';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NewLogo from "../../assets/images/logo.png";
import { getToken, refreshPage } from '../../utils/Common';
/**
 *  Login page
 */
const Login = () => {
  const userToken = getToken(); 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  

  const onFinish = async (values) => {
    setIsLoading(true);
    values.username = values.email;
    const response = await login(values);
    if (response.data){
      localStorage.setItem('token', JSON.stringify(response.data.token));
      localStorage.setItem('user', JSON.stringify(response.data.id));
      localStorage.setItem('employee_id', JSON.stringify(response.data.employee_id));
      localStorage.setItem('name', JSON.stringify(response.data.name));
      localStorage.setItem('role_id', JSON.stringify(response.data.role_id));
      localStorage.setItem('role_name', JSON.stringify(response.data.role));
      // localStorage.setItem('fullname', JSON.stringify(response.data.full_name));
      localStorage.setItem('token_expiry', JSON.stringify(response.data.token_expiry));
      toast.success("Login successfully!");
      setTimeout(()=>{
        switch(response.data.role?.toLowerCase()) {
          case "admin":
            navigate("/dashboard");
            refreshPage();
            break;
          case "hr":
            navigate("/dashboard");
            refreshPage();
            break;
          case "employee":
            navigate("/employees/view", {state: {passedId: response.data.employee_id}});
            refreshPage();
            break;
          default:
            toast.error("Unauthorized user")
            break;
        }
      }, 1000)
    } else {
      toast.error(response.error?.messages?.error)
    }
    setIsLoading(false);
  };
  

  // useEffect(() => {
  //   if (userToken) {
  //     navigate("/dashboard");
  //   }
  // }, [userToken, navigate])


  return (
    <Container fluid>
      <Row className=''>
        <Col xs={12} lg={6} className='justify-content-center align-items-center login-left-side' style={{height: ''}}>
            <Row className='d-flex justify-content-center'>
              <img src = {NewLogo} alt={"logo"} className="login-logo"/>
            </Row>  
            <Row className="company-address text-center mt-1">
              <span>{window.$company_address}</span> 
            </Row>
        </Col>
        <Col xs={12} lg={6} className='d-flex justify-content-left login-right-side login-section'>
          <Container className='login-form-container'>
            <Title style={{textAlign:'center', marginBottom:40}} level={2}><span className='primary-color'>Login</span> to your account</Title>
            <Form
              layout='vertical'
              name="normal_login"
              className="login-form mt-3"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: 'Username is required',
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Password is required',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item className='d-flex justify-content-center'>
                <Button type="primary" htmlType="submit" className="login-form-button px-5" loading={isLoading}>
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
export default Login;