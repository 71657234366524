import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Space,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Form,
  DatePicker,
  Input, Tabs
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import "quill/dist/quill.snow.css";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllDepartments,
  GetAllPositions,
} from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import ReactQuillEditor from "../../components/ReactQuillEditor/ReactQuillEditor";
import { capitalizeEachWord, getIsAdminLogin } from "../../utils/Common";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

const { Option } = Select;

export default function Announcement() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isAdminLogin = getIsAdminLogin();
  const [content, setContent] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const positionOptions = GetAllPositions();
  const departmentOptions = GetAllDepartments();
  const dateFormat = "YYYY-MM-DD";
  // const [searchTerm, setSearchTerm] = useState(null);

  const dropdownOptions = {
    department: departmentOptions.slice(1),
    position: positionOptions.slice(1),
  };

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
  });

  const tableColumns = [
    {
      title: "Date Posted",
      dataIndex: "announcement_date",
      key: "announcement_date",
      render: (announcement_date, record) => (
        <div>{moment(announcement_date).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Announcement From",
      dataIndex: "announcement_from",
      key: "announcement_from",
    },
    {
      title: "Announcement To",
      dataIndex: "details",
      key: "details",
      render: (details, record) => (
        <ul
          onClick={() => handleRowClick(record)}
          className="pointer-cursor custom-list"
        >
          {details.map((value, index) => (
            <li key={index}>{value.announcement_to}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => <div>
        {capitalizeEachWord(data)}
      </div>
    },
  ];

  const onFinish = (formInputs) => {
    formInputs.announcement_date =
      formInputs.announcement_date.format(dateFormat);
    formInputs.type = formInputs.recipients.map((item) => item.type);
    formInputs.announcement_to = formInputs.recipients.map(
      (item) => item.announcement_to
    );
    callPost("announcements/add", formInputs, false);
  };

  const onEdit = (formInputs) => {
    formInputs.announcement_date =
      formInputs.announcement_date.format(dateFormat);
    formInputs.type = formInputs.recipients.map((item) => item.type);
    formInputs.announcement_to = formInputs.recipients.map(
      (item) => item.announcement_to
    );
    callPost(`announcements/update/` + clickedRowData.id, formInputs, false);
  };

  const confirmDelete = (announcement) => {
    modal.confirm({
      title: `Are you sure you want to delete this announcement?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(announcement.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("announcements/delete", { announcement_id: passedId }, false);
    resetValues();
  }

  const confirmApprove = (announcement) => {
    modal.confirm({
      title: `Are you sure you want to approve this announcement?`,
      icon: <CheckCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApprove(announcement.id),
    });
  };

  function handleApprove(passedId) {
    callPost(
      "announcements/approve",
      { announcement_id: passedId, status: "approved" },
      false
    );
    resetValues();
  }

  function resetValues() {
    form.resetFields();
    setContent("");
  }

  // const handleSearch = (e) => {
  //   if (e.key === "Enter") {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }
  // };

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({ ...prev, [field]: e }));
  };

  const handleRowClick = (rowData) => {
    if (!window.$isEmployeeLogin) {
      setContent(rowData.content);
      setClickedRowData(rowData);
      const formattedValues = { ...rowData };
      formattedValues.announcement_date = rowData.announcement_date
        ? dayjs(rowData.announcement_date, dateFormat)
        : "";
      form.setFieldsValue(formattedValues);
      setShowViewModal(true);
    } else {
      navigate("view", { state: rowData });
    }
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const handleQuillChange = (html) => {
    setContent(html);
    form.setFieldValue("content", html);
  };

  // React.useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(searchTimeout);
  //   };
  // }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest("announcements/get", filterConfig);
      if (response.data) {
        const res = response.data.announcements.map((data) => {
          var info = data;
          info.recipients = data.details.map((rec) => {
            var recInfo = {};
            recInfo.type = rec.type;
            recInfo.announcement_to = rec.announcement_to_id;
            return recInfo;
          });
          return info;
        });
        setTableData(res);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Announcements"
      pageButtonTitle="Add Announcement"
      onClickButton={() => {
        setShowAddModal(true);
        setShowViewModal(false);
      }}
      isManager
    >
      <Tabs
        defaultActiveKey="pending"
        className="mt-4"
        items={[
          { key: "pending", label: "Pending" },
          { key: "approved", label: "Approved" },
          { key: "disapproved", label: "Disapproved" },
          { key: "", label: "All" },
        ]}
        type="card"
        onChange={(e) => setFilterConfig((prev) => ({ ...prev, status: e }))}
      />
        <Space className="justify-content-start" wrap>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Position"
            optionFilterProp="items"
            filterOption={(input, option) => option.label.includes(input)}
            options={positionOptions}
            onChange={(e) => handleFilter(e, "position_id")}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Department"
            optionFilterProp="items"
            filterOption={(input, option) => option.label.includes(input)}
            options={departmentOptions}
            onChange={(e) => handleFilter(e, "department_id")}
          />
        </Space>

      <Table
        className=""
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Announcement`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
        maskClosable={false}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="announcement_date"
                label="Date Posted"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="announcement_from"
                label="Announcement From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="content"
                label="Content"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactQuillEditor
                  content={content}
                  handleQuillChange={handleQuillChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            Recipients
            <Form.List name="recipients" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Row
                        key={key}
                        style={{ marginBottom: 0 }}
                        className="d-flex justify-content-between"
                      >
                        <Col xs={11}>
                          <Form.Item
                            name={[name, "type"]}
                            fieldKey={[fieldKey, "type"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select the recipient type",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Type"
                              onChange={(value) => {
                                // update form everytime this is changed in order to access the announcement dropdown options
                                form.setFieldsValue({
                                  [`${name}.type`]: value,
                                });
                              }}
                            >
                              <Option value="department">Department</Option>
                              <Option value="position">Position</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={11}>
                          <Form.Item
                            name={[name, "announcement_to"]}
                            fieldKey={[fieldKey, "announcement_to"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select the announcement to",
                            //   },
                            // ]}
                          >
                            <Select
                              placeholder="Announcement To"
                              options={
                                dropdownOptions[
                                  form.getFieldValue([
                                    "recipients",
                                    name,
                                    "type",
                                  ])
                                ]
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Announcement"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="announcement_date"
                label="Date Posted"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="announcement_from"
                label="Announcement From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="content"
                label="Content"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactQuillEditor content={content} isDisabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            Recipients
            <Form.List name="recipients" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Row
                        key={key}
                        style={{ marginBottom: 0 }}
                        className="d-flex justify-content-between"
                      >
                        <Col xs={12}>
                          <Form.Item
                            name={[name, "type"]}
                            fieldKey={[fieldKey, "type"]}
                           
                          >
                            <Select placeholder="Type" disabled>
                              <Option value="department">Department</Option>
                              <Option value="position">Position</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            name={[name, "announcement_to"]}
                            fieldKey={[fieldKey, "announcement_to"]}
                           
                          >
                            <Select
                              disabled
                              placeholder="Announcement To"
                              options={
                                dropdownOptions[
                                  form.getFieldValue([
                                    "recipients",
                                    name,
                                    "type",
                                  ])
                                ]
                              }
                            />
                          </Form.Item>
                        </Col>
                       
                      </Row>
                    ))}
                  </Col>
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={clickedRowData.status === "approved"}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData);
                }}
                icon={<CheckCircleOutlined />}
                disabled={clickedRowData.status === "approved" || !isAdminLogin}
              >
                Approve
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", { state: clickedRowData });
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={!isAdminLogin}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
