import { getUser, getToken, refreshPage } from "../Common";
import axios from "axios";
import toast from "react-hot-toast";

// Set up a global response interceptor
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Handle 401 error
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        toast.error("Unauthorized. Logging you out...", { autoClose: 5000 });
        setTimeout(() => {
          refreshPage();
          window.location.href = "/"; // Redirect to the login page
        }, 1000);
      }
  
      // Propagate the error to the calling code
      return Promise.reject(error);
    }
  );

const postCall = async (url, parameters) => {
    const config = {
        headers: {
            'api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        // params: {requester: getUser(), token: getToken()}
    }
    try {
        // const response = await axios.post(process.env.REACT_APP_BASE_URL+"/"+url, qs.stringify(data), config);
        const response = await axios.post(process.env.REACT_APP_BASE_URL + url, parameters, config);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

export const getCall = (url, parameters) => {
    const config = {
        headers: {
            'api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: parameters
    }

    return axios.get(process.env.REACT_APP_BASE_URL + url, config);
};

export const UseGetRequest = async (url, filter) => {
    if (filter) {
        const tempTerm = (filter && filter.term && filter.term.trim() !== '') ? filter.term.trim() : null;
        filter.term = tempTerm; 
    }    

    try {
        const response = await getCall(url, {requester: getUser(), token: getToken(), ...filter});
        return {data: response.data}
    } catch (error) {
        return {error: error.response}
    }
}

// DON'T BE TRICKED, THIS MAY BE POST BUT THIS ACTUALLY IS A GET INSIDE A POST METHOD.
// BACKEDN tingz I have to conform to
export const UsePostRequest = async (url, filter) => {
    if (filter) {
        const tempTerm = (filter && filter.term && filter.term.trim() !== '') ? filter.term.trim() : null;
        filter.term = tempTerm; 
    }   

    try {
        const response = await postCall(url, {requester: getUser(), token: getToken(), ...filter});
        return {data: response.data || response.employees, response: response}
    } catch (error) {
        return {error: error.response}
    }
}
