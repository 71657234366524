import React, {useState, useRef} from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Table, Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import ReactToPrint from "react-to-print";
import {UsePostRequest} from "../../utils/api/ApiRequests";
import moment from "moment";


export default function PrintPhicContributionReport () {
    const location = useLocation();
    const componentRef = useRef();
    const dateToday = new Date();
    // const [isFetching, setIsFetching] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totals, setTotals] = useState({});
    const [filterConfig, setFilterConfig] = useState({
        date_from: location.state.date_from,
        date_to: location.state.date_to,
    });

    React.useEffect(() => {
        async function fetchTableData () {
            setTableData([]);
            setTotals({});
            const response = await UsePostRequest("contributions/get_phic_report", filterConfig);
            if (response.data) {
                setTableData(response.data);
                setTotals(response.response.grand_total);
            }
        }

        fetchTableData();
    }, [filterConfig])

    return (
        <Navbar isForm>
            <Container className="print-report-page p-3 view-page">
                <div ref={componentRef}>
                    <Row className="center tiny-text italic">Republic of the Philippines</Row>
                    <Row className="center bold">PHILIPPINE HEALTH INSURANCE CORPORATION</Row>
                    <Row className="center italic">www.philhealth.gov</Row>

                    <Row className="justify-content-end mt-3">
                        <Col xs={16} md={12} lg={9}>
                            <Row>
                                <Col xs={9} className="">Date/Time Generated</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={13} className="d-flex justify-content-end">{dateToday.toLocaleString()}</Col>
                            </Row>
                            <Row>
                                <Col xs={9} className="">Applicable Period</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={13} className="d-flex justify-content-end">{`${moment(filterConfig.date_from).format("MM-YYYY")}`}</Col>
                            </Row>
                            <Row>
                                <Col xs={9} className="">Report Type</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={13} className="d-flex justify-content-end">Regular</Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <Row className="center mt-3 bold">EMPLOYEES PREMIUM REMITTANCE LIST</Row>
                    <Row className="mt-3">
                        <Col md={7} lg={4} className="">Philhealth Number :</Col>
                        <Col className="bold">{window.$company_philhealth_no}</Col>
                    </Row>
                    <Row className="">
                        <Col md={7} lg={4} className="">Employer Name :</Col>
                        <Col className="bold uppercase">{window.$company_name}</Col>
                    </Row>
                    <Row className="">
                        <Col md={7} lg={4} className="">Employer Address :</Col>
                        <Col className="bold" wrap>{window.$company_address}</Col>
                    </Row>
                    <Row className="">
                        <Col md={7} lg={4} className="">Employer TIN :</Col>
                        <Col className="bold">{window.$company_tin}</Col>
                    </Row>
                    <Row className="">
                        <Col md={7} lg={4} className="">Employer Type :</Col>
                        <Col className="bold">{window.$company_employer_type}</Col>
                    </Row>

                    <div className="mt-3">
                        <Table className="table-header-only-borders stripe-table" >
                            <thead>
                                <tr className="text-center">
                                    <th>No.</th>
                                    <th>PhilHealth No.</th>
                                    <th>Surname</th>
                                    <th>Given Name</th>
                                    <th>Middle Name</th>
                                    <th>PS</th>
                                    <th>ES</th>
                                    <th>Status | Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (tableData && tableData.length) ? (
                                        tableData.map((row, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{index}</td>
                                                    <td className="text-center">{row.phic}</td>
                                                    <td>{row.last_name}</td>
                                                    <td>{row.first_name}</td>
                                                    <td>{row.middle_name}</td>
                                                    <td className="text-end">{row.employee_contribution}</td>
                                                    <td className="text-end">{row.employer_contribution}</td>
                                                    <td>{row.status}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr className="text-center">
                                            <td colSpan={8}>No Data Found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <tbody className="bordered-body">
                                <tr className="text-end">
                                    <td colSpan={5} className="bold">Subtotal</td>
                                    <td>{totals.total_employee_contribution ? Number(totals.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                    <td>{totals.total_employer_contribution ? Number(totals.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                    <td></td>
                                </tr>
                                <tr className="text-end">
                                    <td colSpan={5} className="bold">GRAND TOTAL</td>
                                    <td colSpan={2} className="bold">{totals.total_total ? Number(totals.total_total).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <Row className="ms-2">
                        <Col xs={24} md={12} className="mt-5">
                            <Row className="ms-5">Prepared by:</Row>
                            <Row className="mt-5 center uppercase">{window.$company_hr}</Row>
                            <Row className="mt-2 center text-center">Signature over Printed Name and Designation</Row>
                        </Col>
                        <Col xs={24} md={12} className="mt-5">
                            <Row className="ms-5">Certified True and Correct by:</Row>
                            <Row className="mt-5 center uppercase">{window.$company_ceo}</Row>
                            <Row className="mt-2 center text-center">Signature over Printed Name and Designation</Row>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center mt-4 mt-5 mb-5 noPrint">
                        <Col className="d-flex justify-content-center print">
                        <ReactToPrint
                            trigger={() => (
                            <Button type="primary">
                                <PrinterOutlined /> Print
                            </Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"PhilHealth Report"}
                        />
                        </Col>
                    </Row>

                </div>
            </Container>
        </Navbar>
    )
}