import React, { createContext, useEffect, useState, useMemo } from 'react';
import PropTypes from "prop-types";

const TabContext = createContext();

const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(null);

  // Retrieve active tab value from browser storage on component mount
  useEffect(() => {
    const storedActiveTab = sessionStorage.getItem('activeTab');
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);

  // Update active tab value and store in browser storage
  const setActiveTabValue = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem('activeTab', tab);
  };

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab: setActiveTabValue}}>
      {children}
    </TabContext.Provider>
  );
};

TabProvider.propTypes = {
  children: PropTypes.node,
}
export { TabProvider, TabContext };
