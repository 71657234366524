import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Divider, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import Title from "antd/es/typography/Title";
import Navbar from "../../components/Navbar/Navbar";
import logo from "../../assets/images/logo.png";
import {
  capitalizeEachWord,
  formatDate,
} from "../../utils/Common";
import ReactToPrint from "react-to-print";

export default function ViewWorkFromAnywhere() {
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const passedData = location.state;
  const dateToday = new Date();

  return (
    <Navbar isForm>
      <Container className="p-4 view-page">
        <div ref={componentRef}>
          <Row className="d-flex justify-content-end">
            <Title level={3}>WFA</Title>
          </Row>
          <Row className="d-flex justify-content-end">
            Date Printed: {formatDate(dateToday)}
          </Row>
          <Row className="mt-3">
          <Col xs={3} className="me-3">
              <img src={logo} alt="logo" className="view-page-logo" />
            </Col>
            <Col>
              <Row>
                <Title level={3}>{window.$company_name}</Title>
              </Row>
              <Row>{window.$company_address}</Row>
            </Col>
          </Row>
          <Divider />

          <Row className="p-1 mt-3">
            <Col xs={24} md={12} lg={12}>
              <table className="table table-bordered">
                <thead>
                  <tr className="primary-bg white-text ">
                    <th colSpan={2}>WFA No. {passedData.id}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bold w-50">Employee Name</td>
                    <td>
                      {passedData.employee_name
                        ? capitalizeEachWord(passedData.employee_name)
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold w-50">Status</td>
                    <td>{capitalizeEachWord(passedData.status)}</td>
                  </tr>
                  <tr>
                    <td className="bold w-50">Date Applied</td>
                    <td>{formatDate(passedData.added_on)}</td>
                  </tr>
                  <tr>
                    <td className="bold w-50">Date</td>
                    <td>{formatDate(passedData.date)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row className="mt-4">
            <Title level={5}>PURPOSE</Title>
          </Row>
          <Divider />
          <Row className="px-5 py-2">
            <div
              dangerouslySetInnerHTML={{
                __html: passedData.remarks ? passedData.remarks : "",
              }}
            />
          </Row>
          <Divider />
        </div>

        <Row className="d-flex justify-content-end noPrint">
          <Row>
            <Button
              className="cancel-btn me-2"
              onClick={() => navigate("/wfas")}
            >
              Cancel
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined /> Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Row>
        </Row>
      </Container>
    </Navbar>
  );
}
